import {
  SET_ACCESSORIES_USED,
  SET_PDL_SELECTED,
  SET_PRODUCT_DETAILS,
  SET_RENTAL_PRICES,
  SET_RENTAL_START_DATE,
} from 'pages/Resabike/actions/resabikeActions';
import { RentalBikeActionType } from '../types/actions';
import { PriceTableType } from '../types/prices';
import { BikeProductType } from '../types/product';
import { ResaAccessoryItemType } from '../types/resabike';

type ResaBikeState = {
  priceTable: PriceTableType | null;
  rentalStartDate: string;
  productDetails: BikeProductType | null;
  accessoriesUsed: ResaAccessoryItemType[] | null;
  pdlSelected: number | null;
};

const initialState: ResaBikeState = {
  priceTable: null,
  rentalStartDate: '',
  productDetails: null,
  accessoriesUsed: null,
  pdlSelected: null,
};

const rentalBikeReducer = (state: ResaBikeState = initialState, action: RentalBikeActionType): ResaBikeState => {
  switch (action.type) {
    case SET_RENTAL_PRICES:
      const { priceTable } = action.payload;

      return {
        ...state,
        priceTable,
      };

    case SET_RENTAL_START_DATE:
      const { rentalStartDate } = action.payload;
      return {
        ...state,
        rentalStartDate,
      };
    case SET_PRODUCT_DETAILS:
      const { productDetails } = action.payload;

      return {
        ...state,
        productDetails,
      };
    case SET_ACCESSORIES_USED:
      const { accessoriesUsed } = action.payload;

      return {
        ...state,
        accessoriesUsed,
      };
    case SET_PDL_SELECTED:
      const { pdlSelected } = action.payload;

      return {
        ...state,
        pdlSelected,
      };
    default:
      return state;
  }
};

export default rentalBikeReducer;
