import { combineReducers } from '@reduxjs/toolkit';

import appState from 'internal/reducers/appReducer';
import userState from 'pages/Account/reducer/userReducer';
import reservationState from 'pages/Reservations/reducers/reservationReducer';
import rentalBikeState from 'pages/Resabike/reducers/resabikeReducer';
import renewalState from 'pages/Renewal/reducers/renewalReducer';

const rootReducer = combineReducers({
  appState,
  userState,
  reservationState,
  rentalBikeState,
  renewalState,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
