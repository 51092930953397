/* eslint-disable max-len */

export default {
  translations: {
    global: {
      home: 'Home',
      sign_in: 'Sign in',
      sign_up: 'Sign up',
      error: 'Error message',
      send: 'OK',
      my_account: 'My account',
      book: 'Rent',
      workshop: 'Workshop',
      my_reservations: 'My rentals',
      my_infos: 'My account',
      my_invoices: 'My invoices',
      sign_out: 'Sign out',
      bikes: 'Bikes',
      service: 'Service',
      storage: 'Bike parking',
      rent_bike: 'Rent a bike',
      rent_storage_free: 'Rent a parking slot',
      rent_storage: 'Rent a parking slot',
      insurance: 'Insurance',
      park: 'Parking',
      page_not_found: {
        title: 'Page not found',
        wording: 'Oops! An error has slithered under your wheel.',
      },
      go_home: 'Back to home',
      go_back: 'Previous',
      repair_and_overhaul: 'Issue with your bike ?',
      renewal: 'Renewal',
      waiting_list: 'Waiting list',
      delete: 'Delete',
    },
    navbar: {
      contact: 'Contact us',
    },
    routes: {
      SIGN_IN: 'Sign in',
      SIGN_UP: 'Sign up',
      CONTACT: 'Contact us',
      HOME: 'Home',
      MAIL_VALIDATION: 'Confirm your email',
      FORGOTTEN_PWD: 'Lost your password?',
      CHANGE_PWD: 'Password management',
      MY_ACCOUNT: 'My account',
      MY_RESERVATIONS: 'My rentals',
      MY_INVOICES: 'My invoices',
      RENT_BIKE: 'Rent a bike',
      RENT_STORAGE: 'Rent a bike parking slot',
      RENT_PARK: 'Rent a bike parking slot',
      WORKSHOP: 'Workshop',
      WAITING_LIST: 'Waiting list',
    },
    splash: {
      loading: "Don't move, cycling in progress...",
      loading_title: "Don't move !",
      loading_description: 'Cycling in progress !',
    },
    bookingInProgress: {
      alert: {
        title: {
          default: 'Incomplete subscription',
          inWaitingListVariant: 'This is your turn !',
        },
        text: {
          default:
            'Please complete your subscription within the next <countdown /> minutes or your reservation will time out.',
          inWaitingListVariant:
            'You have the chance to be the next in the waiting list !<br />Please complete your subscription within the next <countdown /> or your subscrtiption will time out.',
        },
        link: {
          inWaitingListVariant: 'Finalize your subscription',
        },
      },
      modal: {
        title: 'Incomplete subscription',
        content_default:
          'Do you want to finalize your incomplete subscription ? or start a new one ? <br /><br />If you start a new one, your incomplete subscription from waiting list will be lost.',
        content_inWaitingList:
          'VDo you want to finalize your incomplete subscription ? or start a new one ? <br /><br />If you start a new one, your incomplete subscription will be lost.',
        actions: {
          newRental: 'New subscription',
          finishRental: 'Finalize subscription',
          continueRental: 'Resume incomplete subscription',
        },
      },
    },
    error: {
      veloAvailable: 'A bike is now available',
      invalidAbotc: 'Navigo Travel Card is not correct',
      motif_utilisationIsMissing: "Le motif d'utilisation est obligatoire",
      wrongBookingToken: 'Votre réservation a expiré',
      listeAttenteNotValid: "Réservation en liste d'attente invalide",
      resaExpired: 'Votre réservation a expiré',
      alreadyInQueue: "Vous êtes déjà dans la liste d'attente",
      invalidPasReservation: 'Heure invalide, veuillez choisir une heure de réservation valide',
      notAvailableAccessoire: 'Les accessoires choisis ne sont pas disponibles',
      creneauUnavailable: 'Your rental starting date schedule is no longer available. Please choose another one.',
      minDelaiReservation:
        "La date de démarrage sélectionnée n'est plus disponible (délai minimum requis). Veuillez en sélectionner une autre.",
      default: 'An unexpected error occurred. Please try again later.',
      mandatoryField: 'This field is required',
      missingParameters: 'You have not filled all required fields.',
      codeOrValidated: 'Please verify the information provided. Be careful: A code can only be validated once.',
      alreadyAccount: 'You are already registered with this email address. Please log in!',
      wrongCredentials: 'You have entered a wrong username or wrong password',
      unknownError: 'An unexpected error occurred. Please try again later.',
      invalidOrExpiredToken: 'Your session expired. Please log in.',
      missingToken: 'Your session expired. Please log in.',
      emailNotValidated: 'You have not validated your email address yet. Please check your inbox and log in.',
      expiredBooking: 'Your booking has expired. Please go through the booking process again',
      noAvailability: 'No availability for the requested dates. Please modify your booking dates or try again.',
      maxResaCapacity: 'You have reached the maximum number of bookings for your account.',
      unknownUser: 'Unknown email address. Please check!',
      pleaseWait: 'You can only request a new password reset every 10 minutes.',
      passwordTooShort: 'Your password is too short.',
      passwordsDontMatch: "The password and its confirmation don't match.",
      wrongResetCode: 'The link you are using seems broken.',
      resetCodeExpired: 'The link you are using seems broken. Please go through the password reset process again.',
      resetCodeAlreadyUsed:
        'You have already used this password reset link. Please go through the password reset process again.',
      wrongPayment: 'Payment verification failed.',
      wrongFileFormat: "Your file isn't a png, pdf or jpeg file",
      deliveryPointClosed: 'Please respect the opening time of the delivery point!',
      mustBeAnAdult: 'You have to be of age',
      invalidDate: 'Invalid date format',
      mandatory_voucher: 'Mandatory voucher',
      boursierIsMissing: 'Scolarship is missing',
      unavailableStartDate: 'Date de début indisponible. Veuillez choisir une autre date.',
      ibanIsEmpty: 'IBAN obligatoire',
      bicIsEmpty: 'BIC obligatoire',
    },
    home: {
      welcome: 'Welcome',
      no_resa: 'You have no rental ongoing',
      on_tool: 'on Veloclik',
      locality: '',
      subtitle_0: 'You have currently ',
      reservations: '{{ count }} rental',
      reservations_plural: '{{ count }} rentals',
      subtitle_1: 'ongoing',
    },
    sign_in: {
      has_account: 'Already have an account ?',
      please_sign_in: 'Please sign in',
      forgotten_pwd: 'Lost your password ?',
      sign_up: 'Sign up',
      no_account: 'No account yet ?',
      forgotten_password: 'You have just received an email with instructions to reset your password !',
      pwd_reset_success: 'Your password has been reset with success!',
      pwd_change_success: 'Your password has been updated with success!',
      log_in: 'Sign in',
    },
    sign_up: {
      title: 'Sign up',
      subtitle: 'Create your account online',
      privacy_policy: 'Please see our <1>Privacy Notice</1>',
      type_a_phone: 'At least one phone number must be filled in.',
      type_all_phones: 'You should fill a phone and mobile phone number.',
      newsletter: "I'd like to receive more information about {{collectivityName}} (new features, news, ...)",
      already_an_account: 'Already have an account?',
      please_sign_up: 'Please sign up',
      nature: 'Customer type',
      individual: 'Individual',
      association: 'Charity organization',
      company: 'Company',
      administration: 'Public administration',
      civility: 'Title',
      mr: 'Mr',
      mrs: 'Mrs/Ms',
      firm: 'Company',
      firm_or_asso: 'Company / Charity / School',
      last_name: {
        label: 'Last name',
        placeholder: 'Your last name',
      },
      first_name: {
        label: 'First name',
        placeholder: 'Your first name',
      },
      password_field: {
        label: 'Password',
        helper:
          'Password must be at least 10 characters, inlucindg 1 upercase, 1 lowercase, 1 digit and 1 special character',
      },
      cta: 'Create my account',
      email: 'Email',
      birthdate: 'Birthdate',
      origin_country: 'Country of birth',
      num_adherent: 'Membership ID',
      date_exp_adherent: 'Membership expiration date',
      motif_adhesion: 'Membership reason',
      motif_adhesion_autre: 'Membership reason : Other',
      school_bike: 'Bike school',
      other: 'Other',
      workshop: 'Workshop',
      location: 'Location',
      address: 'Address',
      full_address: 'Address complement',
      zip: 'Postcode',
      city: 'City',
      country: 'Country',
      phone: 'Phone number',
      mobile_phone: 'Cellphone number',
      email_error: 'Email format is incorrect.',
      password_error:
        'Password must be at least 10 characters, inlucindg 1 upercase, 1 lowercase, 1 digit and 1 special character !',
      password_requirements:
        'Password must be at least 10 characters, inlucindg 1 upercase, 1 lowercase, 1 digit and 1 special character.',
      sign_up_success:
        'Thanks for signing up ! Please follow instructions in email we have just sent you to validated your account !',
      copy_password: 'Password (confirmation)',
      my_pwd: 'My password',
      change_pwd_description: 'For security reasons, you have to set your password on first connection.',
    },
    contact: {
      title: 'Contact us',
      contact_us: 'Contact us',
      subtitle: 'Find all contact information about the service',
      form: {
        name: {
          label: 'Last name',
          placeholder: 'Your lastname',
        },
        email: {
          label: 'Email',
          placeholder: 'Your email',
        },
        message: {
          label: 'Your message',
          placeholder: 'Your message',
        },
        submit: {
          label: 'Submit',
        },
      },
      let_your_contact:
        'Please do not hesitate to contact us if you have any question or if you need more information about the service !',
      success: 'Your message has been submitted with success !',
      input_label: 'Sender:',
    },
    forgotten_pwd: {
      title: 'Lost your password ?',
      description: "Don't worry, it can happen to anyone!",
      long_description: 'Did you lost your password ?\nPlease follow instructions to get a new password.',
      button: 'Requet a new password',
      new_password_field: {
        label: 'New password',
        placeholder: 'Password',
      },
      confirm_new_password_field: {
        label: 'Confirm your new password',
        placeholder: 'Password',
      },
    },
    mail_validation: {
      title: 'Email confirmation',
      please_verify: 'Please enter your email address and the security code you have just received by email',
      code: 'Security code',
      verify: 'Confirm my account',
      has_no_account: 'No account yet ?',
      forgotten_pwd: 'Lost your password ?',
      email_validated: 'Your email has been validated with success ! You can now sign in !',
    },
    my_account: {
      update_infos: 'Edit my account',
      infos_updated: 'Your account has been updated with success !',
      you_can_modify: 'You can edit your personal details here.',
    },
    my_reservations: {
      priceWithTaxes: 'Price inc tax',
      no_reservations: {
        title: 'No rental ongoing',
        description: 'No rental ongoing. This is now time to book your first ride !',
      },
      title: 'Mes réservations',
      subtitle: 'Please find all your rentals in this section',
      dates: 'from {{ startDate }} to {{ endDate }}',
      inscription: 'Subscription in waitinglist since since {{ date }} {{ article }}',
      unsubscribe: 'Unsubscribe',
      no_contracts: 'No rental',
      list_reservations: {
        in_progress: 'Ongoing',
        past: 'Past',
      },
      consult_here: 'Please find all your rentals in this section.',
      current_reservations: 'Ongoing rentals ({{currentResa}})',
      past_reservations: 'Past rentals ({{pastResa}})',
      checkout_link: 'Checkout',
      renew: 'Renew',
      start_date: 'Start date',
      end_date: 'End date',
      booking_at: 'Booked on',
      cancel_at: 'Cancelled on ',
      queue_position: 'Waiting list position',
      added_to_queue_at: 'Subscrided on',
      place_of_withdrawal: 'Location point',
      empl: 'Location',
      unsubscribe_success: 'You have been unsubscribed from waiting list with success !',
      unsubscribe_success_title: 'Waiting list',
      contract: 'Terms and contract',
      status: {
        validée: 'Validated',
        'en attente': 'Waiting for renter validation',
        "liste d'attente": 'Waiting list',
        annulée: 'Cancelled',
        terminée: 'Finished',
        renewal: 'Renewed',
      },
    },
    my_invoices: {
      price_type: 'inc tax',
      price_currency: '{{ devise }}',
      no_invoice_title: 'No invoice to list yet',
      no_invoice_description: 'This is now time to book your first ride !',
      header_description: 'View all your invoices here',
      tab_title: 'Invoice',
      invoice: 'Invoice {{ numFacture }} ',
      cta: 'Start a new rental',
      security_deposit: '+ security deposit',
    },
    workshop: {
      title: 'Workshop',
      meet_at_workshop: 'Meet us at the workshop!',
      check_up:
        'Does your bike need a check-up? Feel free to contact us about all questions you may have about your bike maintenance.',
      address: 'Address',
      opening_times: 'Opening hours',
      opening_hours: 'Monday through Friday: 7.30AM - 12.00PM & 2.00PM - 7.30PM',
      saturday: 'Saturday: 10.00AM - 1.00PM',
      street: '1 ter Place Charles de Gaulle',
      city: '78180 Montigny-le-Bretonneux',
      tel: 'Phone: {{ item }}',
      mail: 'Email : {{ item }}',
    },
    duration: {
      year: 'year',
      month: 'month',
      day: 'day',
      trimester: 'quarter',
    },
    renewal: {
      title: 'Renewal',
      subtitle: 'Renew your rental',
      you_choose_a: "You've decided to renew your rental as follows :",
      pdl_addr: 'Location point office :  {{ emplOrPdlName }} <br/>{{ emplOrPdlAddress }}<br /> ',
      type_renewal: ' - renewal',
    },
    resabike: {
      infos_section_title: 'Personal informations',
      dates_section_title: 'Rental period',
      dates_section_subtitle: 'Please select your expected pickup schedules matching with remaining availabilities.',
      need_grt: 'You must accept Terms and Rental Conditions',
      read_grt: 'I agree with Terms and Rental Conditions',
      read_grt_with_link: {
        firstSentence: 'I agree with',
        linkText: 'Terms and Rental Conditions',
      },
      ttc_subtitle: 'Prices include taxes',
      button_resa: 'Select this bike',
      no_availability: 'Out of stock',
      no_storage_title: 'No more parking available',
      no_storage_description:
        'We have no  parking available for this location at this moment.\nFeel free to check for another location !',
      no_bike_title: 'No more bike available',
      no_bike_description:
        'We have no longer bike available for this location at this moment.\nFeel free to check for another location !',
      book_a_bike: 'Select your bike :',
      inscription_waiting_list: 'Register on waiting list',
      rental_prices: 'Rental prices for a bike',
      standard: 'standard',
      choose_duration_price: 'Select your price :',
      choose_duration: 'Select your price :',
      back: 'Previous page',
      book: 'BOOK',
      choose: 'Select this price',
      description: 'Service offers a variety of different bikes',
      select_title: 'Select your location :',
      select_accessories: 'Select your accessories :',
      pre_selected_accessories: 'Selected accessories :',
      no_tariff_for_accessories: 'No price available for this renewal including accessories',
      accessories_renewal: 'Accessories :',
      accessories_used: 'Selected accessories for this renewal :',
      select_holder: 'Please select your location !',
      bike_number: '{{bikeNumber}} bikes',
      park_number: '{{bikeNumber}} parking slots',
      free_price: 'Free',
      bikes: 'bikes',
      parks: 'parkings',
      deposit_tooltip_text: 'Cashed deposit for this price will be ',
      monthlyPaymentInfo: 'or starting at {{price}} a month',
      bike_disponibility: '{{ typeArticle }} availables',
      waiting_list_link: 'Register on waiting list',
      accessories_disponibility: 'Available accessories : ',
      bike_price: '{{bikePrice}} {{ devise }}',
      from_price: 'Price from : ',
      secured_park: 'parking',
      ttc_day: 'inc tax / day',
      ttc: 'inc tax',
      other_price: '+ other prices',
      form_start_date: 'Starting date',
      form_end_date: 'Ending date',
      form_duration_start: 'Please select your rental period matching with location opening hours :',
      you_choose_a: 'You have selected a ',
      you_choose_a_park: 'You have selected a',
      iban: 'IBAN',
      bic: 'BIC',
      choose_accessories: 'Select your accessories : ',
      accessories_no_thank: 'No accessory, thanks !',
      accessory_remove_cta: 'Remove',
      accessory_cta: 'Select this accessory',
      accessory_go_payment: 'Proceed to paiement',
      use_reason: 'Rental usage',
      reason: 'Select a rental usage',
      service_trial: 'Service Trial',
      work: 'Work',
      hobbies: 'Leisure',
      contact_form_title: 'Contact informations',
      others: 'Others',
      invalid_iban: 'IBAN is invalid',
      invalid_bic: 'BIC is invalid',
      duration: 'Duration',
      min: 'minimum',
      max: 'maximum',
      for_other: 'Rental is for someone else',
      electric: 'Do you have an e-bike?',
      of_rental: 'of rental for the chosen price:',
      selected_duration: 'Selected duration :',
      id_card: "Proof of identity (ID card, driver's licence...)",
      RIB: 'Banking Details (for the deposit)',
      if_you_dont_have: "If you don't have these proofs, please visit one of our agencies.",
      please_give_IBAN: 'Please provide your banking details to make your reservation process easier and quicker:',
      process_payment: 'Proceed to payment',
      your_data_wont_saved: " (your data won't be saved)",
      duration_price: '(which comes to {{ price }} {{ devise }}, inc tax',
      you_choose: 'You have chosen:',
      you_choose_accessories: 'Vous avez chosen following accessories : ',
      rental_is_to: 'This rental will be assigned to: ',
      total_cost: 'Total price: ',
      free_total: 'Free rental',
      total_price_accessories: 'Total price, accessories included : {{ price }} {{ devise }} inc tax',
      please_wait: 'Please wait...',
      wait_validation: 'Your payment is being processed...',
      congratulation: 'Congratulations!',
      pay: 'Pay',
      bike_reserved: 'Your rental has been submitted !',
      storage_reserved: 'Your parking slot has been booked with success !',
      recap_caution_title: 'Deposit',
      recap_choice: 'You have selected : ',
      recap_choice_v2: 'ou have selected : ',
      recap_location: 'This rental will be assigned to : ',
      recap_total: 'Total price',
      recap_title: 'Payment summary',
      we_have_several_bikes: 'We offer a variety of different bikes.',
      we_have_several_parks: 'We offer a variety of parking slots.',
      payment_invalid: 'Payment failed',
      select_article: 'Select this price',
      show_tariffs: 'See prices',
      email_advice: 'You will receive a confirmation by email shortly !',
      validate: 'Validated',
      validate_reservation: 'Validate',
      finalize_reservation: 'Go back to My reservations',
      insurance_hook: 'Needs an insurance to prevent bike outtage ? Click here (new window)',
      article_type: {
        consigne: 'parking',
        consignepark: 'parking',
        velo: 'bike',
      },
      pdl_addr: "You'll pick your bike up at : {{ emplOrPdlName }} <br/>{{ emplOrPdlAddress }}<br />  - {{ type }}",
      clean_form: 'Empty forms',
      security_deposit_label: 'Cashed deposit',
      security_deposit: 'Cashed deposit : {{ securityDepositAmount }} {{ devise }}',
      security_deposit_prices: 'Cashed deposit : {{ securityDepositAmount }} {{ devise }}',
      security_deposit_choose: ' + Cashed deposit',
      security_deposit_more_info: 'More information',
      accept_cgl: 'I have read and accept ',
      register: 'Register',
      document_section_title: 'Requested supporting documents :',
    },
    resa_storage: {
      select_type: 'Select your location :',
      title_header: 'Rent a parking slot',
      button_resa: 'Select this parking',
      dates_section_title: 'Rental dates',
      dates_section_subtitle: 'Please select your rental dates matching with location opening hours :',
      transport_card: {
        first_descr: 'A valid Navigo Card is',
        second_descr: 'MANDATORY',
        third_descr: 'to get access to our secure parking spaces! It is composed of 10 numbers',
        fourth_descr: '(with no letters)',
        fifth_descr: 'and is displayed on your card',
      },
      transport_card_placeholder: 'Navigo Card ID',
      transport_card_upload: 'Proof of purchase of your Navigo Card',
      btn_pay: 'Proceed to payment',
      invalid_transport_card: 'You Navigo ID is not valid',
      modal_transport_card: {
        link: 'Where is the number?',
        title: 'Where can I find the number?',
        close: 'Close',
        first_descr: 'Type in the number',
        second_descr: 'WITH NO SPACE AND NO LETTERS',
        third_descr: 'of your Navigo Card (see above)',
        fourth_descr: 'Example: for',
        fifth_descr: '0123456789',
      },
    },
    repair_overhaul: {
      breakdown_form_notice: 'Problem description is mandatory',
      breakdown_type: 'Breakdown type',
      prevent: 'I need a maintenance',
      breakdown: 'I have a breakdown',
      stolen_bike: 'My bike has been stolen/lost',
      needs_info: 'I need some information about the service',
      gear: 'Equipment ID',
      issue_desc: 'Problem description',
      contact: 'Contacts information',
      submit: 'Submit your request',
      submit_success: 'Your request has been submitted with success !',
      status: 'Status',
      select_status: 'Select your status',
      student: 'Scolarship student',
      back_to_work: 'Back-to-work young people',
      validation_title: 'Your request has been submitted with success !',
      validation_ticket_number: 'Ticket ID',
      validation_lead: 'Your request has been submitted with success with <b>ID #{{ticketId}}</b>',
      validation_line_one: 'Keep preciously this ID !',
      validation_line_two: 'We will come back to you very soon',
      back_home: 'Back to homepage',
    },
    waiting_list: {
      validation_lead:
        'You have registered to the waiting list with success. Your position in the queue : <b>{{ticketId}}</b>',
    },
    languages: {
      fr: 'FR 🇫🇷',
      en: 'EN 🇬🇧',
    },
    alert: {
      success: 'Success !',
      error: 'An error occurred !',
    },
    form: {
      notice: 'Fields with * are mandatory.',
      all_required: 'All fields must be filled.',
      required: 'This field must be filled',
      email: 'Email is not valid',
      invalidFormat: 'Format is not valid',
      invalidOption: 'Please enter a value',
    },
  },
};
