/* eslint-disable max-len */

export default {
  translations: {
    global: {
      home: 'Accueil',
      sign_in: 'Se connecter',
      sign_up: "S'inscrire",
      error: "Message d'info / d'erreur",
      send: 'Envoyer',
      my_account: 'Mon compte',
      book: 'Réserver',
      workshop: "L'atelier",
      my_reservations: 'Mes réservations',
      my_infos: 'Mes informations',
      my_invoices: 'Mes factures',
      sign_out: 'Se déconnecter',
      bikes: 'Vélos',
      service: 'Le service',
      storage: 'Consignes libres',
      rent_bike: 'Réserver un vélo',
      rent_storage_free: 'Réserver 1 consigne libre',
      rent_storage: 'Réserver 1 consigne',
      insurance: 'Assurance',
      park: 'Consignes',
      page_not_found: {
        title: 'Oups !',
        wording: "La page que vous demandez n'existe pas (ou plus). Toutes nos excuses pour cette mauvaise direction.",
      },
      page_wrong: {
        title: 'Quelque chose s’est mal passé.',
        wording: 'Nous éprouvons des difficultés à afficher cette page.\n' + 'Merci de rafraîchir la page.',
      },
      go_home: "Revenir à l'accueil",
      refresh: 'Rafraichir la page',
      go_back: 'Précédent',
      repair_and_overhaul: 'Réparation et révision',
      renewal: 'Renouvellement',
      waiting_list: "Liste d'attente",
      delete: 'Supprimer',
      legal_section: 'Légal',
      cgvelo_link: 'Conditions générales de location',
      legal_link: 'Mentions légales',
      privacy_link: 'Politique de confidentialité',
    },
    navbar: {
      contact: 'Nous contacter',
    },
    routes: {
      SIGN_IN: 'Se connecter',
      SIGN_UP: "S'inscrire",
      CONTACT: 'Contact',
      HOME: 'Accueil',
      MAIL_VALIDATION: 'Vérification de votre adresse email',
      FORGOTTEN_PWD: 'Mot de passe oublié ?',
      CHANGE_PWD: '',
      MY_ACCOUNT: 'Mon compte',
      MY_RESERVATIONS: 'Mes réservations',
      MY_INVOICES: 'Mes factures',
      RENT_BIKE: 'Réserver un vélo',
      RENT_STORAGE: 'Réserver une consigne',
      RENT_PARK: 'Réserver une consigne',
      WORKSHOP: 'Page Atelier',
      WAITING_LIST: "Liste d'attente",
    },
    splash: {
      loading: 'Ne bougez pas, ça pédale fort..',
      loading_title: 'Ne bougez pas !',
      loading_description: 'Ca pédale fort !',
    },
    bookingInProgress: {
      alert: {
        title: {
          default: 'Réservation en cours',
          inWaitingListVariant: 'C’est à vous !',
        },
        text: {
          default: 'Une réservation est actuellement en cours. Celle-ci est encore disponible <countdown /> minutes.',
          inWaitingListVariant:
            'Le vélo que vous souhaitiez est disponible !<br /> Vous avez encore <countdown /> pour confirmer cette réservation',
        },
        link: {
          default: 'Continuer la réservation',
          inWaitingListVariant: 'Finaliser la réservation',
        },
      },
      modal: {
        title: 'Une réservation est actuellement en cours',
        content_default:
          'Voulez-vous la poursuivre ou en faire une nouvelle ?<br /><br />Si vous ne souhaitez pas la poursuivre, celle-ci sera perdue.',
        content_inWaitingList:
          'Voulez-vous finaliser la réservation en liste d’attente ? Ou démarrer une nouvelle réservation.<br /><br />Si vous ne souhaitez pas la finaliser, celle-ci sera perdue.',
        actions: {
          newRental: 'Nouvelle réservation',
          finishRental: 'Finaliser la réservation',
          continueRental: 'Poursuivre la réservation',
        },
      },
    },
    error: {
      veloAvailable: 'Un vélo est finalement disponible',
      invalidAbotc: "Le numéro du passe navigo n'est pas bon",
      motif_utilisationIsMissing: "Le motif d'utilisation est obligatoire",
      wrongBookingToken: 'Votre réservation a expiré',
      listeAttenteNotValid: "Réservation en liste d'attente invalide",
      resaExpired: 'Votre réservation a expiré',
      alreadyInQueue: "Vous êtes déjà dans la liste d'attente",
      invalidPasReservation: 'Heure invalide, veuillez choisir une heure de réservation valide',
      notAvailableAccessoire: 'Les accessoires choisis ne sont pas disponibles',
      creneauUnavailable: 'Créneau non disponible',
      minDelaiReservation:
        "La date de démarrage sélectionnée n'est plus disponible (délai minimum requis). Veuillez en sélectionner une autre.",
      default: "Une erreur inattendue s'est produite. Veuillez réessayer plus tard",
      mandatoryField: 'Ce champ est obligatoire',
      missingParameters: "Vous n'avez pas renseigné tous les champs obligatoires.",
      codeOrValidated:
        "Merci de vérifier les informations saisies. Attention : vous ne pouvez valider votre code qu'une seule fois.",
      alreadyAccount: 'Votre êtes déjà inscrit avec cette adresse email, merci de vous connecter !',
      wrongCredentials: 'Votre email ou votre mot de passe est incorrect',
      unknownError: "Une erreur inattendue s'est produite. Veuillez réessayer plus tard.",
      invalidOrExpiredToken: 'Votre session est expirée. Merci de vous reconnecter.',
      missingToken: 'Votre session est expirée. Merci de vous reconnecter.',
      emailNotValidated:
        "Vous n'avez pas encore validé votre adresse email. Vous pourrez ensuite vous connecter. Merci.",
      expiredBooking: 'Votre réservation est expirée. Nous vous invitons à recommencer le processus de réservation.',
      noAvailability:
        'Aucune disponibilité aux dates demandées. Veuillez changer vos dates de réservation ou renouveler votre réservation ultérieurement.Merci',
      maxResaCapacity: 'Vous avez atteint le maximum de réservations simultanées.',
      unknownUser: "Il semblerait que votre adresse email n'existe pas.",
      pleaseWait: 'Vous pouvez demander un nouveau mot de passe toutes les 10 minutes.',
      passwordTooShort: 'Votre mot de passe est trop court.',
      passwordsDontMatch: 'Le mot de passe et sa confirmation ne sont pas identiques.',
      wrongResetCode: 'Le lien que vous utilisez semble incorrect.',
      resetCodeExpired:
        'Le lien que vous utilisez est expiré, veuillez recommencer la procédure de mot de passe oublié.',
      resetCodeAlreadyUsed:
        'Vous avez déjà utilisé ce lien pour renouveler votre mot de passe, veuillez recommencer la procédure de mot de passe oublié.',
      wrongPayment: "Une erreur s'est produite avec le paiement",
      wrongFileFormat: "Votre document n'est pas au format png, pdf ou jpeg",
      deliveryPointClosed: "Veuillez choisir une heure dans les horaires d'ouverture du point de livraison !",
      mustBeAnAdult: 'Vous devez être majeur',
      invalidDate: 'Ce format de date est invalide',
      mandatory_voucher: 'Pièce jointe obligatoire',
      boursierIsMissing: 'Champ boursier manquant',
      unavailableStartDate: 'Date de début indisponible. Veuillez choisir une autre date.',
      ibanIsEmpty: 'IBAN obligatoire',
      bicIsEmpty: 'BIC obligatoire',
    },
    home: {
      welcome: 'Bienvenue',
      no_resa: "Vous n'avez pas de réservation en cours",
      on_tool: "sur l'outil de location de vélos et de consignes de la Communauté d'agglomération de",
      locality: 'Saint-Quentin-en-Yvelines',
      subtitle_0: 'Vous avez ',
      reservations: '{{ count }} réservation ',
      reservations_plural: '{{ count }} réservations ',
      subtitle_1: 'en cours',
    },
    sign_in: {
      has_account: 'Je possède un compte',
      please_sign_in: 'Merci de vous connecter',
      forgotten_pwd: 'Mot de passe oublié ?',
      sign_up: "S'inscrire",
      no_account: 'Vous n’avez pas encore de compte ?',
      forgotten_password: 'Un email vous a été envoyé pour réinitialiser votre mot de passe.',
      pwd_reset_success: 'Votre mot de passe a bien été réinitialisé',
      pwd_change_success: 'Votre mot de passe a bien été enregistré',
      log_in: 'Se connecter ?',
    },
    sign_up: {
      title: 'Je m’inscris',
      subtitle: 'Je pourrais ainsi réserver mon premier vélo',
      privacy_policy: 'Consultez la <1>Politique de confidentialité</1>',
      type_a_phone: 'Veuillez saisir au moins un numéro de téléphone',
      type_all_phones: 'Veuillez saisir un numéro de téléphone et un numéro de téléphone portable.',
      newsletter: 'Je souhaite recevoir des informations sur {{collectivityName}} (nouveautés, actualités, ...)',
      already_an_account: 'Déjà un compte ?',
      please_sign_up: 'Merci de vous inscrire',
      nature: 'Nature',
      individual: 'Particulier',
      association: 'Association',
      company: 'Société',
      administration: 'Administration',
      civility: 'Civilité',
      mr: 'Monsieur',
      mrs: 'Madame',
      firm: 'Entreprise',
      firm_or_asso: 'Entreprise / Association / École',
      last_name: {
        label: 'Nom',
        placeholder: 'Votre Nom',
      },
      first_name: {
        label: 'Prénom',
        placeholder: 'Votre Prénom',
      },
      password_field: {
        label: 'Mot de passe',
        helper:
          'Votre mot de passe doit comporter 10 caractères minimum, dont au moins 1 majuscule, 1 minuscule, 1 chiffre et 1 caractère spécial.',
      },
      newsletterField: {
        label: 'Je souhaite recevoir des informations sur {{collectivityName}} (nouveautés, actualités, ...)',
      },
      cta: 'Créer mon compte',
      email: 'Email',
      birthdate: 'Date de naissance',
      origin_country: "Pays d'origine",
      num_adherent: 'Numéro adhérent',
      date_exp_adherent: "Date d'expiration adhérent",
      motif_adhesion: "Motif d'adhésion",
      motif_adhesion_autre: "Motif d'adhésion autre",
      school_bike: 'Vélo-école',
      other: 'Autre',
      workshop: 'Atelier',
      location: 'Location',
      address: 'Adresse',
      full_address: 'Adresse complémentaire',
      zip: 'Code Postal',
      city: 'Ville',
      country: 'Pays',
      phone: 'Téléphone fixe',
      mobile_phone: 'Téléphone portable',
      email_error: "Le format d'email est incorrect",
      password_error: 'Min. 8 caractères et uniquement des lettres avec au moins une majuscule',
      password_requirements:
        'Votre mot de passe doit contenir min. 8 caractères et uniquement des lettres avec au moins une majuscule',
      sign_up_success:
        'Merci pour votre inscription, vous allez recevoir un email qui vous permettra de valider votre inscription',
      copy_password: 'Mot de passe (confirmation)',
      my_pwd: 'Mon mot de passe',
      change_pwd_description:
        'Pour des raisons de sécurité, suite à votre première connexion depuis votre passage en agence, vous devez changer votre mot de passe reçu par email.',
    },
    contact: {
      title: 'Contact',
      contact_us: 'Contactez-nous',
      subtitle: "N'hésitez pas à nous écrire.",
      form: {
        name: {
          label: 'Nom',
          placeholder: 'Votre nom',
        },
        email: {
          label: 'Email',
          placeholder: 'Votre adresse mail',
        },
        message: {
          label: 'Votre message',
          placeholder: 'Votre message',
        },
        submit: {
          label: 'Envoyer',
        },
      },
      let_your_contact:
        "N'hésitez pas à nous contacter et nous laisser vos coordonnées pour que nous puissions vous répondre !",
      success: 'Votre demande nous a bien été transmise !',
      input_label: 'Expéditeur du message :',
    },
    forgotten_pwd: {
      title: 'Mot de passe oublié ?',
      description: 'Pas de panique, ça arrive à tout le monde !',
      long_description:
        'Vous avez perdu votre mot de passe.\n Renseignez l’adresse de votre compte pour recevoir un mail de réinitialisation.',
      button: 'Réinitialiser mon mot de passe',
      new_password_field: {
        label: 'Nouveau mot de passe',
        placeholder: 'Mot de passe',
      },
      confirm_new_password_field: {
        label: 'Confirmation nouveau mot de passe',
        placeholder: 'Mot de passe',
      },
    },
    mail_validation: {
      title: 'Vérification de votre adresse email',
      please_verify: 'Merci de saisir votre adresse email et le code reçu par email',
      code: 'Code',
      verify: 'Vérifier',
      has_no_account: 'Pas encore de compte ?',
      forgotten_pwd: 'Mot de passe oublié ?',
      email_validated: 'Votre adresse est validée, vous pouvez maintenant vous connecter !',
    },
    my_account: {
      update_infos: 'Modifier mes informations',
      infos_updated: 'Vos informations ont bien été mises à jour !',
      you_can_modify: 'Vous pouvez modifier vos informations personnelles ici',
    },
    my_reservations: {
      priceWithTaxes: 'Prix TTC',
      no_reservations: {
        title: 'Aucune réservation pour le moment',
        description: 'Nous n’avons aucune réservation à afficher, pourquoi ne pas remédier à ca ?',
      },
      title: 'Mes réservations',
      subtitle: 'Consultez ici vos réservations en cours et passées',
      dates: 'du {{ startDate }} au {{ endDate }}',
      inscription: "Inscription file d'attente : le {{ date }} {{ article }}",
      unsubscribe: 'Se désinscrire',
      no_contracts: 'Aucun contrat',
      list_reservations: {
        in_progress: 'En cours',
        past: 'Passés',
      },
      consult_here: 'Consultez ici vos réservations en cours et passées.',
      current_reservations: 'Réservations en cours ({{currentResa}})',
      past_reservations: 'Réservations passées ({{pastResa}})',
      checkout_link: 'État des lieux',
      returnAppointment: 'Planifier la fin de location',
      renew: 'Renouveler',
      start_date: 'Date de début',
      end_date: 'Date de fin',
      return_date: 'Retour de location le',
      booking_at: 'Reservé le',
      cancel_at: 'Annulé le ',
      queue_position: 'Place dans la file',
      added_to_queue_at: 'Inscrit dans la file le',
      place_of_withdrawal: 'Lieu de retrait',
      yourTurn: 'C’est à vous !',
      empl: 'Emplacement',
      unsubscribe_success: "Vous avez bien été désinscrit de la file d'attente",
      unsubscribe_success_title: 'Succès',
      contract: 'Voir le contrat',
      status: {
        validée: 'Validée',
        'en attente': 'En attente du prestataire',
        "liste d'attente": "Liste d'attente",
        annulée: 'Annulée',
        terminée: 'Terminée',
        renewal: 'Renouvelée',
      },
    },
    returnAppointment: {
      content:
        'Votre location prendra fin le <strong>{{endDate.date}}, à {{endDate.time}}</strong>, pour un retour de votre location <blue>au plus tard</blue> le <strong>{{returnDate.date}}, {{returnDate.time}}</strong>.<br/><br/>Vous avez la possibilité de planifier le retour de votre location en modifiant la date et l’heure.',
    },
    my_invoices: {
      price_type: 'TTC',
      price_currency: '€',
      no_invoice_title: 'Aucune facture en cours',
      no_invoice_description:
        'Probablement car vous n’avez pas encore fait de réservation.\n' + 'Pourquoi ne pas commencer dès maintenant ?',
      header_description: 'Consultez ici toutes vos factures',
      tab_title: 'Facture',
      invoice: 'Facture {{ numFacture }} ',
      cta: 'Nouvelle réservation',
      security_deposit: '+ dépôt de garantie',
    },
    workshop: {
      title: "L'Atelier",
      meet_at_workshop: "Rendez-vous à l'atelier.",
      subtitle:
        "Votre vélo a besoin d'un check-up ? Nous vous proposons un service de maintenance incluant réglages et répération courantes. Vous y touverez également des conseils et des réponses à toutes vos questions sur l'entretien de votre vélo.",
      address: 'Adresse',
      opening_times: "Horaires d'ouverture",
      opening_hours: 'lundi au vendredi : 7h30 - 12h et 14h - 19h30',
      saturday: 'samedi : 10h - 13h',
      street: '1 ter Place Charles de Gaulle',
      city: '78180 Montigny-le-Bretonneux',
      tel: 'Tel: {{ item }}',
      mail: 'Email: {{ item }}',
    },
    duration: {
      year: 'année',
      month: 'mois',
      day: 'jour',
      trimester: 'trimestre',
    },
    renewal: {
      title: 'Renouvellement',
      subtitle: 'Renouvelez votre réservation',
      you_choose_a: 'Vous avez choisi de renouveler votre réservation avec le tarif ',
      pdl_addr:
        "Merci de vous rendre à l'accueil de {{ emplOrPdlName }} <br/>{{ emplOrPdlAddress }}<br /> pour récupérer votre vélo",
      type_renewal: ' - renouvellement',
    },
    resabike: {
      infos_section_title: 'Informations nominatives',
      dates_section_title: 'Dates et heures de réservation',
      dates_section_subtitle:
        'Veuillez choisir le jour et l’heure auxquels vous souhaitez prendre rendez-vous pour récupérer le vélo.',
      privacyPolicy:
        '{{collName}} traite les données recueillies pour la location de vélo et place de parking. Pour en savoir plus sur la gestion de vos données personnelles et pour exercer vos droits, reportez-vous à notre <a>Politique de confidentialité.</a>',
      need_grt: 'Vous devez accepter les Conditions Générales de Location',
      read_grt: "J'ai pris connaissance et accepte les Conditions Générales de Location",
      read_grt_with_link: {
        firstSentence: 'J’ai pris connaissance et accepte les',
        linkText: 'Conditions Générales de Location',
      },
      /*read_grt_with_link:
        'J\'ai pris connaissance et accepte les\u00a0<a href="{{ link }}" target="_blank">Conditions Générales de Location</a>',*/
      ttc_subtitle: 'Les prix indiqués sont TTC',
      button_resa: 'Réserver ce vélo',
      no_availability: 'Aucun',
      no_storage_title: 'Plus aucune consigne disponible',
      no_storage_description:
        'Nous n’avons plus de consigne disponible dans cet emplacement.\n' +
        'Mais n’hésitez pas à regarder dans les autres !',
      no_bike_title: 'Plus aucun vélo disponible',
      no_bike_description:
        'Nous n’avons plus de vélo disponible dans ce point\n' +
        'de location. Mais n’hésitez pas à regarder dans les autres !',
      book_a_bike: 'Réserver un vélo',
      inscription_waiting_list: "Inscription en liste d'attente",
      rental_prices: 'Les tarifs de location pour un velo ',
      standard: 'standard',
      choose_duration_price: 'Choisissez une durée de location et un tarif ',
      choose_duration: 'Choisissez une durée de location ',
      back: 'Revenir à la page précédente',
      book: 'RÉSERVER',
      choose: 'Choisir',
      description: 'Nous vous proposons différents types de vélos.',
      select_title: 'Choisissez votre point de location :',
      select_accessories: 'Choisissez vos accessoires :',
      pre_selected_accessories: 'Accessoires sélectionnés pour ce renouvellement :',
      no_tariff_for_accessories: 'Tarif indisponible pour ce renouvellement et ses accessoires.',
      accessories_renewal: 'Accessoires :',
      accessories_used: 'Accessoires utilisés pour ce renouvellement :',
      select_holder: 'Veuillez sélectionner un point de location',
      bike_number: '{{bikeNumber}} vélos',
      park_number: '{{bikeNumber}} consignes',
      free_price: 'Gratuit',
      bikes: 'vélos',
      parks: 'consignes',
      deposit_tooltip_text: 'Le dépôt de garantie pour cette durée est de',
      monthlyPaymentInfo: 'ou à partir de {{price}}/mois',
      bike_disponibility: '{{ typeArticle }} disponibles',
      waiting_list_link: "S'inscrire en liste d'attente",
      accessories_disponibility: 'Accessoires disponibles : ',
      bike_price: '{{bikePrice}} {{ devise }}',
      from_price: 'Tarif à partir de : ',
      secured_park: 'consigne sécurisée',
      ttc_day: 'TTC / jour',
      ttc: 'TTC',
      other_price: '+ autres tarifs',
      form_start_date: 'Date de début',
      form_end_date: 'Date de fin',
      form_duration_start:
        "Veuillez choisir le jour et l'heure auxquels vous souhaitez prendre rendez-vous pour récupérer le vélo :",
      you_choose_a: 'Vous avez choisi un',
      you_choose_a_park: 'Vous avez choisi une',
      iban: 'IBAN',
      bic: 'BIC',
      choose_accessories: 'Choisissez des accessoires',
      choose_insurance: 'Choisissez une garantie',
      accessories_no_thank: 'Non, merci!',
      accessory_remove_cta: 'Retirer',
      accessory_cta: 'Ajouter cet accessoire',
      accessory_go_payment: 'Passer au paiement',
      use_reason: "Motif d'utilisation",
      reason: "Sélectionnez le motif d'utilisation",
      service_trial: 'Essai du service',
      work: 'Travail',
      hobbies: 'Loisirs',
      contact_form_title: 'Vos informations de contact',
      others: 'Autres',
      invalid_iban: 'Votre IBAN est invalide',
      invalid_bic: 'Votre BIC est invalide',
      duration: 'Durée',
      min: 'minimum',
      max: 'maximum',
      for_other: 'Je réserve pour quelqu’un d’autre',
      electric: 'Vous avez un vélo électrique ?',
      of_rental: 'de location pour le tarif choisi :',
      selected_duration: 'Durée sélectionnée :',
      id_card: "Justificatif d'identité (carte d'identité recto/verso, permis de conduire, ...)",
      RIB: "Relevé d'Identité Bancaire (pour la pénalité non encaissée)",
      if_you_dont_have:
        "Si vous n'avez pas ces justificatifs à portée de main, vous pourrez les apporter directement en agence.",
      please_give_IBAN: 'Merci de renseigner votre IBAN et votre BIC pour faciliter votre réservation :',
      process_payment: 'Procéder au paiement',
      your_data_wont_saved: ' (attention, vos données ne seront pas sauvegardées)',
      duration_price: '(soit {{ price }} {{ devise }} TTC environ)',
      you_choose: 'Vous avez choisi : ',
      you_choose_accessories: 'Vous avez choisi les accessoires : ',
      rental_is_to: 'Cette location sera attribuée à : ',
      total_cost: 'Coût total : ',
      free_total: 'Rien à régler',
      total_price_accessories: 'Prix total, avec accessoires : {{ price }} {{ devise }} TTC',
      please_wait: 'Veuillez patienter...',
      wait_validation: 'Validation de votre paiement en cours...',
      congratulation: 'Félicitations !',
      pay: 'Payer',
      bike_reserved: 'Votre vélo est réservé !',
      storage_reserved: 'Votre consigne est réservée !',
      recap_caution_title: 'Caution non-encaissée',
      recap_choice: 'Vous avez choisi le modèle : ',
      recap_choice_v2: 'Vous avez choisi le modèle : ',
      recap_location: 'Cette location sera attribuée à : ',
      recap_total: 'Total',
      recap_title: 'Récapitulatif',
      we_have_several_bikes: 'Nous vous proposons différents types de vélos.',
      we_have_several_parks: 'Nous vous proposons différents types de consignes.',
      payment_invalid: 'Une erreur est survenue lors du paiement',
      select_article: 'Sélectionner cet article',
      show_tariffs: 'Voir les tarifs',
      email_advice: 'Nous vous avons envoyé une confirmation par mail.',
      validate: 'Valider',
      validate_reservation: 'Valider la réservation',
      finalize_reservation: 'Finaliser ma réservation',
      insurance_hook:
        "Besoin d'une assurance rachat de caution contre la casse et le vol pour votre vélo ? Cliquez-ici (nouvelle fenêtre)",
      article_type: {
        consigne: 'consigne',
        consignepark: 'consigne libre',
        velo: 'vélo',
      },
      pdl_addr:
        "Merci de vous rendre à l'accueil de {{ emplOrPdlName }} <br/>{{ emplOrPdlAddress }}<br /> pour récupérer votre {{ type }}",
      clean_form: 'Cliquez ici pour vider le formulaire.',
      security_deposit_label: 'Dépôt de garantie',
      security_deposit: 'Dépôt de garantie encaissé : {{ securityDepositAmount }} {{ devise }}',
      security_deposit_prices: 'Dépôt de garantie : {{ securityDepositAmount }} {{ devise }}',
      security_deposit_choose: ' + Dépôt de garantie',
      security_deposit_more_info: 'En savoir plus',
      accept_cgl: "J'ai pris connaissance et accepte ",
      register: "S'inscrire",
      document_section_title: 'Documents et justificatifs',
    },
    resa_storage: {
      select_type: 'Choisissez votre emplacement :',
      title_header: 'Réserver une consigne',
      button_resa: 'Réserver cette consigne',
      dates_section_title: 'Dates de réservation',
      dates_section_subtitle:
        'Veuillez choisir le jour auquel vous souhaitez prendre rendez-vous pour récupérer votre consigne.',
      transport_card: {
        first_descr: 'Le passe Navigo en cours de validité est',
        second_descr: 'OBLIGATOIRE',
        third_descr: 'pour accéder à nos consignes sécurisées !! Il est composé de 10 chiffres',
        fourth_descr: '(sans la lettre)',
        fifth_descr: 'et est imprimé sur votre pass.',
      },
      transport_card_placeholder: 'Numéro de passe Navigo',
      transport_card_upload: 'Justificatif d’achat de votre passe Navigo',
      btn_pay: 'Procéder au paiement',
      invalid_transport_card: 'Votre numéro de carte est invalide',
      modal_transport_card: {
        link: 'Où est le numéro ?',
        title: 'Où trouver le numéro demandé ?',
        close: 'Fermer',
        first_descr: 'Indiquez le numéro',
        second_descr: 'SANS ESPACE ET SANS LA LETTRE',
        third_descr: 'de votre carte Navigo (voir ci-dessus)',
        fourth_descr: 'Exemple : pour',
        fifth_descr: '0123456789',
      },
    },
    repair_overhaul: {
      breakdown_form_notice: 'La description du problème est obligatoire.',
      breakdown_type: 'Typologie de panne',
      prevent: 'Je souhaite profiter de la révision',
      breakdown: "J'ai une panne ou une casse",
      stolen_bike: 'Mon vélo a été perdu/volé',
      needs_info: "J'ai besoin d'une autre information",
      gear: "Identifiant de l'équipement",
      issue_desc: 'Description du problème',
      contact: 'Informations de contact',
      submit: 'Envoyer votre demande',
      submit_success: 'Nous avons bien reçu votre demande !',
      status: 'Boursier',
      select_status: 'Sélectionnez votre statut',
      student: 'Etudiant boursier',
      back_to_work: "Jeune en parcours d'insertion",
      validation_title: 'Votre demande de réparation est enregistrée.',
      validation_ticket_number: 'Numéro de ticket',
      validation_lead: 'Votre demande de réparation est enregistrée sous le <b>numéro #{{ticketId}}</b>',
      validation_line_one:
        'Nous vous avons envoyé un mail avec toutes les informations nécessaires à communiquer quand nous vous recontacterons prochainement.',
      validation_line_two: 'Gardez précieusement ce numéro de ticket !',
      back_home: "Revenir à l'accueil",
    },
    waiting_list: {
      validation_lead: "Vous avez bien été enregistré dans la liste d'attente. Votre position : <b>{{ticketId}}</b>",
    },
    languages: {
      fr: 'FR 🇫🇷',
      en: 'EN 🇬🇧',
    },
    alert: {
      success: 'Succès',
      error: 'Une erreur est survenue',
    },
    form: {
      notice: "Les champs marqués d'une astérisque sont obligatoires.",
      all_required: 'Tous les champs sont obligatoires.',
      required: 'Ce champ est obligatoire',
      email: "Cet email n'est pas valide",
      invalidFormat: "Le format n'est pas valide",
      invalidOption: 'Veuillez choisir une option',
    },
  },
};
