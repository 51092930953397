import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackbar } from '../internal/actions/appActions';
import SnackbarSeverity from '../internal/constants/snackbar';
import { SelectSnackbarState } from '../internal/selectors/snackbarSelectors';

const AUTO_DISMISS = 5000;

function useSnackbar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { snackbar } = useSelector(SelectSnackbarState);

  useEffect(() => {
    if (snackbar.content !== '') {
      const timer = setTimeout(() => dispatch(setSnackbar('', '')), AUTO_DISMISS);
      return () => clearTimeout(timer);
    }
  }, [snackbar.content]);

  const setAlert = useCallback(
    ({ title, description, severity }: { title?: string; description: string; severity: SnackbarSeverity }) => {
      let snackbarTitle = title;
      if (!snackbarTitle) {
        severity === SnackbarSeverity.ERROR ? (snackbarTitle = t('alert.error')) : (snackbarTitle = t('alert.success'));
      }
      dispatch(setSnackbar(snackbarTitle || '', description, severity));
    },
    []
  );

  return {
    setAlert,
  };
}

export default useSnackbar;
