import axios from 'axios';
import { TIMEOUT } from '../internal/constants/api';
import { RemoteConfigType } from '../internal/types/remoteConfig';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: TIMEOUT,
  headers: {
    ['X-Veloclik-Apikey']: process.env.REACT_APP_API_KEY || '',
  },
});

export function setApiToken(token: string | null) {
  return new Promise<boolean>((resolve) => {
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = token;
      resolve(true);
    } else {
      resolve(false);
    }
    // setTimeout(() => resolve(), 7000);
  });
}

const URL = {
  getTranslations: 'root/translations',
  getRemoteConfig: 'root/config',
};

export function getTranslations(lang: string) {
  return axiosInstance.get(URL.getTranslations, { params: { lang } });
}

export function getRemoteConfig() {
  return axiosInstance.get<RemoteConfigType>(URL.getRemoteConfig);
}
