import ApiExecutor from '@dotmind/utils/dist/react/ApiExecutor';
import axios, { AxiosRequestConfig } from 'axios';
import Mock from 'axios-mock-adapter';
import { LOCALE_ENDPOINT, TIMEOUT } from 'internal/constants/api';
import { addMock, addMockingInterceptors, enableMocking } from '../../utils/mock-axios';
import translations from '../constants/en.json';

const extra = Object.freeze({
  dynamicWording: {
    HOME: {
      title: '',
      subtitle: 'test',
      banner: '',
    },
    SIGN_IN: {
      title: '',
      subtitle: '',
      banner: '',
    },
    SIGN_UP: {
      title: '',
      subtitle: '',
      banner: '',
    },
    MAIL_VALIDATION: {
      title: '',
      subtitle: '',
      banner: '',
    },
    CONTACT: {
      title: '',
      subtitle: '',
      banner: '',
    },
    FORGOTTEN_PWD: {
      title: '',
      subtitle: '',
      banner: '',
    },
    CHANGE_PWD: {
      title: '',
      subtitle: '',
      banner: '',
    },
    MY_ACCOUNT: {
      title: '',
      subtitle: '',
      banner: 'test en anglais',
    },
    MY_RESERVATIONS: {
      title: '',
      subtitle: '',
      banner: '',
    },
    MY_INVOICES: {
      title: '',
      subtitle: '',
      banner: '',
    },
    WORKSHOP: {
      title: '',
      subtitle: '',
      banner: '',
    },
    SIGN_OUT: {
      title: '',
      subtitle: '',
      banner: '',
    },
    RENT_BIKE: {
      title: '',
      subtitle: '',
      banner: '',
    },
    RENT_STORAGE: {
      title: '',
      subtitle: '',
      banner: '',
    },
    RENT_PARK: {
      title: '',
      subtitle: '',
      banner: '',
    },
    INSURANCE: {
      title: '',
      subtitle: '',
      banner: '',
    },
    REPAIR_AND_OVERHAUL: {
      title: 'A good title',
      subtitle: 'A good subtitle',
      banner: '',
    },
    RENEWAL: {
      title: 'A good renewal !',
      subtitle: 'A nice subtitle of renewal',
      banner: '',
    },
    ACCESSOIRES: {
      title: '',
      subtitle: '',
      banner: '',
    },
    LISTE_ATTENTE: {
      title: '',
      subtitle: '',
      banner: '',
    },
  },
});

addMock(LOCALE_ENDPOINT.concat('?lang=en'), { data: { translations: { ...translations, ...extra } } });
enableMocking(false);

if (!process.env.REACT_APP_API_URL || !process.env.REACT_APP_API_KEY)
  throw new Error('Need API_URL and API_KEY env vars');

// eslint-disable-next-line
export type RequestType = AxiosRequestConfig | Record<string, any>;

function apiInstance() {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: TIMEOUT,
    headers: {
      ['X-Veloclik-Apikey']: process.env.REACT_APP_API_KEY || '',
    },
  });

  if (process.env.REACT_APP_API_MOCK === 'true') {
    const mock = new Mock(instance, { onNoMatch: 'passthrough' });

    mock.onPost('user/media64').reply(500);
  }

  return instance;
}

const instance = apiInstance();

export function setApiToken(token: string | null) {
  return new Promise<boolean>((resolve) => {
    if (token) {
      instance.defaults.headers.common['Authorization'] = token;
      resolve(true);
    } else {
      resolve(false);
    }
    // setTimeout(() => resolve(), 7000);
  });
}

function api() {
  addMockingInterceptors(instance);

  return {
    instance,
    ...new ApiExecutor(instance),
    setAuthToken: (token: string) => (instance.defaults.headers.common['Authorization'] = token),
    removeAuthToken: () => {
      delete instance.defaults.headers.common['Authorization'];
    },
  };
}

export default api();
