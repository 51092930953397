import { changeLocale } from 'internal/actions/appActions';
import useApi, { Methods } from 'internal/hooks/useApi';
import useClickOutside from 'internal/hooks/useClickOutside';
import { selectLocale } from 'internal/selectors/localeSelectors';
import { selectDefaultLanguage, selectRemoteLanguages } from 'internal/selectors/remoteConfigSelectors';
import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from '../../pages/Account/selectors/userSelectors';

const LangSelect = () => {
  const { i18n } = useTranslation();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const availableLanguages = useSelector(selectRemoteLanguages);
  const defaultLanguage = useSelector(selectDefaultLanguage);
  const locale = useSelector(selectLocale);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const { request: postLang } = useApi(Methods.POST, 'user/lang');

  const onChangeLocale = useCallback(
    (newLocale: string) => {
      i18n.changeLanguage(newLocale).then(() => {
        if (user) {
          postLang({
            lang: newLocale,
          });
        }

        dispatch(changeLocale(newLocale));
        setIsOpen(false);
      });
    },
    [i18n, changeLocale, user]
  );

  useClickOutside(dropdownRef.current, () => setIsOpen(false));

  return availableLanguages.length > 1 ? (
    <div ref={dropdownRef} className={'relative z-60 md:ml-6 w-[40px] h-[30px]'}>
      <div
        className={
          'text-navbar-color border-navbar-color grid bg-navbar-background grid-cols-1 w-full absolute top-0 right-0 uppercase border-2 cursor-pointer rounded-lg'
        }
      >
        <span onClick={() => setIsOpen(!isOpen)} className={'block px-2 py-1 text-sm font-bold'}>
          {locale || defaultLanguage}
        </span>
        {isOpen && (
          <ul className={'grid grid-cols-1'}>
            {availableLanguages.map((lang) => (
              <li
                key={lang}
                onClick={() => onChangeLocale(lang)}
                className={'text-sm px-2 py-1 hover:text-navbar-hover font-bold'}
              >
                {/* {t(`languages.${lang}`)} */}
                {lang}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  ) : null;
};

export default LangSelect;
