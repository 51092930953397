import { gsap } from 'gsap';
import 'internal/components/Splash.css';
import { selectDefaultLanguage, selectRemoteTheme } from 'internal/selectors/remoteConfigSelectors';
import Lottie from 'lottie-web';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ScrollToTop from '../../components/scrollToTop/ScrollToTop';
import { BgPlaceholder } from '../../const/icons';
import { useLoading } from '../../context/LoadingContext';
import animationData from './splash.json';

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
};

function Splash() {
  const animationRef = useRef<HTMLDivElement>(null);
  const loaderRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { dataIsLoaded, setLoading, loading } = useLoading();
  const defaultLocale = useSelector(selectDefaultLanguage);
  const theme = useSelector(selectRemoteTheme);
  const counterRef = useRef(null);

  useEffect(() => {
    if (animationRef.current) {
      animationRef.current.innerHTML = '';
      Lottie.loadAnimation({
        container: animationRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData,
      });
    }
  }, [animationRef, loading]);

  useEffect(() => {
    if (loading) {
      gsap.fromTo(
        counterRef.current,
        {
          textContent: null,
        },
        {
          duration: 1,
          textContent: '+=100',
          roundProps: 'textContent',
        }
      );

      gsap.fromTo(
        loaderRef.current,
        {
          width: 0,
        },
        {
          width: '100%',
          duration: 1,
        }
      );
    }
  }, [loading, counterRef]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (dataIsLoaded && loading) {
      timeout = setTimeout(() => {
        document.body.style.overflow = 'inherit';
        setLoading(false);
      }, 1500);
    }

    return () => clearTimeout(timeout);
  }, [dataIsLoaded, loading]);

  return loading ? (
    <>
      <ScrollToTop />
      <div className={'splash bg-shade-200'}>
        <div className={'w-full relative h-full grid items-center rounded-2xl bg-white'}>
          <div className={'grid items-center justify-center'}>
            <div className={'relative px-16'}>
              <BgPlaceholder className={'absolute w-full inset-0'} width={400} />
              <div className={''} id={'animation'} ref={animationRef} />
              <div className={'absolute left-1/2 bottom-[10px] transform -translate-x-1/2 '}>
                <span ref={counterRef} className={'text-center block text-[14px] font-bold text-primary-200 z-0'} />
                <div className={'relative w-[50px] h-1 rounded-2xl bg-shade-300'}>
                  <div ref={loaderRef} className={'absolute inset-0 rounded-2xl bg-primary-200'} />
                </div>
              </div>
            </div>

            {defaultLocale ? (
              <div className={'relative z-20 grid text-center justify-center'}>
                <span className={'text-[18px] font-bold mb-2'}>{t('splash.loading_title')}</span>
                <span>{t('splash.loading_description')}</span>
              </div>
            ) : null}
            {theme?.headerLogo && (
              <img
                alt={'collectivity-logo'}
                src={theme.headerLogo}
                className={'absolute bottom-0 left-1/2 -translate-x-1/2 w-[94px] mb-10'}
              />
            )}
          </div>
        </div>
      </div>
    </>
  ) : null;
}

export default Splash;
