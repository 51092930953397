import { SET_RENEWAL_BIKE } from 'pages/Renewal/actions/renewalActions';
import { ReservationDetailsType } from 'pages/Resabike/types/resabike';
import { RenewalBikeActionType } from '../types/actions';

type RenewalBikeState = {
  reservationDetails: ReservationDetailsType | null;
};

const initialState: RenewalBikeState = {
  reservationDetails: null,
};

const renewalBikeReducer = (
  state: RenewalBikeState = initialState,
  action: RenewalBikeActionType
): RenewalBikeState => {
  switch (action.type) {
    case SET_RENEWAL_BIKE:
      const { reservationDetails } = action.payload;

      return {
        ...state,
        reservationDetails,
      };

    default:
      return state;
  }
};

export default renewalBikeReducer;
