import { SetResaActionType, SetResaStatusesActionType } from '../types/actions';
import { ReservationStatuses, ReservationType } from '../types/reservations';

export const SET_RESERVATIONS = 'SET_RESERVATIONS';
export const SET_RESERVATIONS_STATUSES = 'SET_RESERVATIONS_STATUSES';

export const setReservations = (
  currentResaList: ReservationType[],
  pastResaList: ReservationType[]
): SetResaActionType => ({
  type: SET_RESERVATIONS,
  payload: {
    currentResaList,
    pastResaList,
  },
});

export const setReservationsStatuses = (payload: ReservationStatuses): SetResaStatusesActionType => ({
  type: SET_RESERVATIONS_STATUSES,
  payload,
});
