import { SET_RESERVATIONS, SET_RESERVATIONS_STATUSES } from '../actions/reservationActions';
import { ReservationActionType } from '../types/actions';
import { ReservationType } from '../types/reservations';

type ReservationState = {
  currentResaList: ReservationType[] | null;
  pastResaList: ReservationType[];
  inscriptionsWaitingList: ReservationType[];
  totalResa: number;
  currentResa: number;
  pastResa: number;
  inscriptionResa: number;
};

const initialState = {
  currentResaList: null,
  pastResaList: [],
  inscriptionsWaitingList: [],
  totalResa: 0,
  currentResa: 0,
  pastResa: 0,
  inscriptionResa: 0,
};

const reservationsReducer = (
  state: ReservationState = initialState,
  action: ReservationActionType
): ReservationState => {
  switch (action.type) {
    case SET_RESERVATIONS:
      const { pastResaList, currentResaList } = action.payload;

      return {
        ...state,
        currentResaList,
        pastResaList,
      };

    case SET_RESERVATIONS_STATUSES:
      const { totalResa, currentResa, pastResa } = action.payload;

      return {
        ...state,
        totalResa,
        currentResa,
        pastResa,
      };

    default:
      return state;
  }
};

export default reservationsReducer;
