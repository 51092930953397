import React, { ComponentProps, ComponentType, lazy, LazyExoticComponent } from 'react';
import { matchPath } from 'react-router-dom';
import Banner from '../components/banner/Banner';
import { ProductListType } from '../models/Product';

export type RouteType<T = Record<string, unknown>> = {
  path: string;
  layout?: ComponentType;
  element?: LazyExoticComponent<React.ComponentType>;
  privateRoute?: boolean;
  children?: Record<string, RouteType>;
  sampleParams?: T;
} & Partial<Omit<ComponentProps<typeof Banner>, 'children'>>;

export function from<A = Record<string, unknown>, B = Record<string, unknown>>(base: RouteType<A>, add: RouteType<B>) {
  const { path, sampleParams, ...other } = add;

  const result: RouteType<A & B> = {
    path: `${base.path}${path}`,
    sampleParams: base.sampleParams || sampleParams ? Object.assign({}, base.sampleParams, sampleParams) : undefined,
    ...other,
  };

  return result;
}

const home: RouteType = {
  path: '/',
  element: lazy(() => import('pages/Home/Home')),
  height: 'sm',
};

const contact: RouteType = {
  path: '/contact',
  element: lazy(() => import('pages/Contact/Contact')),
};

const signIn: RouteType = {
  path: '/connexion',
  element: lazy(() => import('pages/Auth/SignIn/SignIn')),
};

const signUp: RouteType = {
  path: '/inscription',
  element: lazy(() => import('pages/Auth/SignUp/NewSignUpPage')),
};

const signOut: RouteType = {
  path: '/deconnexion',
  element: lazy(() => import('pages/Auth/SignOut/SignOut')),
};

const mailValidation: RouteType = {
  path: '/validation-email',
  element: lazy(() => import('pages/Auth/EmailValidation/EmailValidation')),
};

const forgottenPassword: RouteType = {
  path: '/mot-de-passe-oublie',
  element: lazy(() => import('pages/Auth/ForgottenPassword/ForgottenPasswordPage')),
};

const changePwd: RouteType = {
  path: '/reset-mot-de-passe',
  element: lazy(() => import('pages/Auth/ChangePassword/ChangePasswordPage')),
};

const myReservations: RouteType = {
  path: '/mes-reservations',
  element: lazy(() => import('pages/Reservations/ListReservations')),
  privateRoute: true,
};

const returnAppointment: RouteType = {
  path: '/:bookingId/retour-location',
  element: lazy(() => import('pages/Reservations/ReturnAppointment')),
};

const myAccount: RouteType = {
  path: '/mes-informations',
  element: lazy(() => import('pages/Account/NewMyAccount')),
  privateRoute: true,
};

const myInvoices: RouteType = {
  path: '/mes-factures',
  element: lazy(() => import('pages/Invoices/MyInvoices')),
  privateRoute: true,
};

const repairAndOverhaul: RouteType = {
  path: '/reparation-et-revision',
  element: lazy(() => import('pages/RepairAndOverhaul/NewRepairAndOverhaul')),
  privateRoute: true,
};

const workshop: RouteType = {
  path: '/probleme-avec-mon-velo',
  element: lazy(() => import('pages/Workshop/Workshop')),
  privateRoute: true,
};

const insuranceError: RouteType = {
  path: '/insurance-error',
  element: lazy(() => import('pages/Error/InsuranceError')),
  privateRoute: true,
};

export type RootResaProductRouteParams = {
  type: ProductListType;
};

const rentBikeRoot: RouteType<RootResaProductRouteParams> = {
  path: '/:type',
  element: lazy(() => import('pages/Resabike/RentBikeTunnel')),
  privateRoute: true,
};

const resaBike: RouteType = from(rentBikeRoot, {
  path: '',
  element: lazy(() => import('pages/Resabike/components/ResaBike')),
  privateRoute: true,
});

export type ResaProductTunnelRouteParams = {
  articleID: string;
  pdl: string;
};

export type ResaProductPricesRouteParams = { pdl: string; articleType: string };

const resaBikePrices = from<RootResaProductRouteParams, ResaProductPricesRouteParams>(rentBikeRoot, {
  path: '/:pdl/:articleType/choix-tarif',
  element: lazy(() => import('pages/Resabike/components/NewRentalPrices')),
  privateRoute: true,
});

const resaBikeAccessories = from<RootResaProductRouteParams, ResaProductTunnelRouteParams>(rentBikeRoot, {
  path: '/:pdl/:articleID/accessoires',
  element: lazy(() => import('pages/Resabike/components/NewResaAccessories')),
  privateRoute: true,
});

const resaBikeInsurance = from<RootResaProductRouteParams, ResaProductTunnelRouteParams>(rentBikeRoot, {
  path: '/:pdl/:articleID/assurance',
  element: lazy(() => import('pages/Resabike/components/ResaInsurance')),
  privateRoute: true,
});

const resaBikeForm = from<RootResaProductRouteParams, ResaProductTunnelRouteParams>(rentBikeRoot, {
  path: '/:pdl/:articleID/mes-informations',
  element: lazy(() => import('pages/Resabike/components/NewRentalForm')),
  privateRoute: true,
});

const resaValidation = from<RootResaProductRouteParams, ResaProductTunnelRouteParams>(rentBikeRoot, {
  path: '/:pdl/:articleID/validation',
  element: lazy(() => import('pages/Resabike/components/RentalValidation')),
  privateRoute: true,
});

const rentBike = Object.assign(rentBikeRoot, {
  children: { resaBike, resaBikePrices, resaBikeForm, resaBikeAccessories, resaBikeInsurance, resaValidation },
});

export type RenewalRootRouteParams = {
  resaId: string;
  type: string;
};

const renewalBikeRoot: RouteType<RenewalRootRouteParams> = {
  path: '/renewal/:resaId/:type',
  element: lazy(() => import('pages/Resabike/RentBikeTunnel')),
  privateRoute: true,
};

const renewalBikePrices = from<RenewalRootRouteParams, ResaProductPricesRouteParams>(renewalBikeRoot, {
  path: '/:pdl/:articleType/choix-tarif',
  element: lazy(() => import('pages/Resabike/components/NewRentalPrices')),
  privateRoute: true,
});

const renewalBikeAccessories = from<RenewalRootRouteParams, ResaProductTunnelRouteParams>(renewalBikeRoot, {
  path: '/:pdl/:articleID/accessoires',
  element: lazy(() => import('pages/Resabike/components/NewResaAccessories')),
  privateRoute: true,
});

const renewalBikeForm = from<RenewalRootRouteParams, ResaProductTunnelRouteParams>(renewalBikeRoot, {
  path: '/:pdl/:articleID/mes-informations',
  element: lazy(() => import('pages/Resabike/components/NewRentalForm')),
  privateRoute: true,
});

const renewalBike = Object.assign(renewalBikeRoot, {
  children: { renewalBikePrices, resaBikeAccessories: renewalBikeAccessories, resaBikeForm: renewalBikeForm },
});

const routes = {
  insuranceError,
  home,
  contact,
  signIn,
  signOut,
  signUp,
  mailValidation,
  forgottenPassword,
  changePwd,
  myReservations,
  returnAppointment,
  myAccount,
  workshop,
  myInvoices,
  repairAndOverhaul,
  rentBike,
  renewalBike,
};

export function getRouteObjectWithPath(path: string): RouteType<unknown> | null {
  const matchRoute = Object.entries(routes).find(([, route]) => matchPath(path, route.path));
  if (matchRoute) return matchRoute[1];

  return null;
}

export type RouteNames = keyof typeof routes;

export default routes;
