import { selectSessionToken } from 'internal/selectors/authSelectors';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const RequireAuthentication = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  return (props: P) => {
    const token = useSelector(selectSessionToken);
    const navigate = useNavigate();

    useEffect(() => {
      !token && navigate('/connexion', { replace: true });
    }, [token]);

    return token ? <WrappedComponent {...props} /> : null;
  };
};

export default RequireAuthentication;
