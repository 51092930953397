import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getRemoteConfig, getTranslations } from '../api/config';
import { useLoading } from '../context/LoadingContext';
import {
  changeLocale,
  saveDefaultLocale,
  saveDynamicWordings,
  savePageWordings,
  saveRemoteConfig,
} from '../internal/actions/appActions';
import { WordingsType } from '../internal/mocks/wordings';
import { selectLocale } from '../internal/selectors/localeSelectors';
import { selectDefaultLanguage } from '../internal/selectors/remoteConfigSelectors';

function withConfig<T>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithConfig = (props: T) => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const { setDataIsLoaded, setLoading } = useLoading();
    const locale = useSelector(selectLocale);
    const defaultLocale = useSelector(selectDefaultLanguage);
    // const { request: postLang } = useApi(Methods.POST, 'user/lang');

    const { data: getConfigResponse } = useQuery(
      ['root/config'],
      () => {
        return getRemoteConfig();
      },
      {
        suspense: false,
      }
    );

    useEffect(() => {
      if (getConfigResponse) {
        dispatch(saveRemoteConfig(getConfigResponse.data));

        dispatch(saveDefaultLocale(getConfigResponse.data.client.languages.default || 'fr'));

        if (locale && !getConfigResponse.data.client.languages.active.includes(locale as 'fr' | 'en')) {
          dispatch(changeLocale(getConfigResponse.data.client.languages.default || 'fr'));
        }
      }
    }, [getConfigResponse]);

    const { isLoading } = useQuery(
      ['root/translations', locale],
      ({ queryKey }) => {
        const [, lang] = queryKey;
        if (lang) return getTranslations(lang);
      },
      {
        suspense: false,
        enabled: Boolean(locale),
        onSuccess: (response) => {
          if (response && 'translations' in response.data) {
            i18n.addResourceBundle(response?.data.locale, 'translations', response?.data.translations, true, true);
            dispatch(savePageWordings(response?.data.translations?.dynamicWording as WordingsType));
            dispatch(
              saveDynamicWordings({
                ...(response?.data.translations?.dynamicStrings as Record<string, string>),
                ...(response?.data.translations?.contactHookInfo as Record<string, string>),
              })
            );
          }

          setDataIsLoaded(true);
        },
      }
    );

    useEffect(() => {
      if (isLoading) {
        setLoading(true);
        setDataIsLoaded(false);
      }
    }, [isLoading]);

    useEffect(() => {
      if (locale && i18n.language !== locale) {
        i18n.changeLanguage(locale);
      } else if (!locale && defaultLocale) {
        dispatch(changeLocale(defaultLocale));
        if (i18n.language !== defaultLocale) i18n.changeLanguage(defaultLocale);
      }
    }, [i18n, locale, defaultLocale]);

    return <WrappedComponent {...props} />;
  };

  ComponentWithConfig.displayName = `withConfig(${displayName})`;

  return ComponentWithConfig;
}

export default withConfig;
