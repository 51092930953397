import 'assets/scss/main.scss';
import 'index.css';

import App from 'internal/components/App';
import store, { persistor } from 'internal/store';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import * as serviceWorker from 'serviceWorker';
import LoadingProvider from './context/LoadingContext';
import './internal/i18n';

const container = document.getElementById('root');
if (!container) throw new Error('container not found!');

const root = createRoot(container);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LoadingProvider>
        <PersistGate loading={null} persistor={persistor}>
          <React.Suspense fallback={<>...</>}>
            <App />
          </React.Suspense>
        </PersistGate>
      </LoadingProvider>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
