import { ThemeColors, ThemeConfig } from 'internal/types/remoteConfig';

/**
 * Build CSS stylesheet from config theme
 * @param theme Theme config
 * @returns {string} CSS stylesheet
 */
export const buildThemeFromRemote = (theme: ThemeConfig & ThemeColors): string => {
  return `
    :root {
      ${Object.keys(theme)
        .map<string | null>((varName) => {
          const configVar = theme[varName as keyof (ThemeColors & ThemeConfig)];
          return configVar ? `--theme-${varName}: ${configVar.replace(/'/g, "\\'")};` : null;
        })
        .join('')}
    } 
  `;
};

/**
 * Append a style to a head element
 * @param id ID of the query selector
 * @param css CSS to inject
 */
export const appendStyle = (id: string, css: string): void => {
  if (!document.head.querySelector('#' + id)) {
    const node = document.createElement('style');
    node.textContent = css;
    node.type = 'text/css';
    node.id = id;

    document.head.appendChild(node);
  }
};

/**
 * Append class names to elements
 * @param theme Theme config
 */
export const appendClassNames = (theme: ThemeConfig): void => {
  const getElement = (selector: string) => document.body.querySelector(selector);
  const elements = [
    { selector: '.navbar', value: `navbar-${theme.navbar_theme}`, enabled: Boolean(theme.navbar_theme) },
  ];
  elements.forEach((e) => {
    const domElement = getElement(e.selector);
    if (domElement && e.enabled && e.value) {
      domElement.classList.add(e.value);
    }
  });
};

/**
 * Check if DOM is ready
 * @returns {boolean}
 */
export const isDOMReady = (): false | HTMLHeadElement => {
  return typeof window !== 'undefined' && typeof document !== 'undefined' && document.head;
};
