import SnackbarSeverity from 'internal/constants/snackbar';
import { RootState } from 'internal/rootReducer';

type State = {
  snackbar: {
    title: string;
    content: string;
    severity: SnackbarSeverity;
  };
  snackbarOpened: boolean;
};

export const SelectSnackbarState = (state: RootState): State => ({
  snackbar: state.appState.snackbar,
  snackbarOpened: state.appState.snackbarOpened,
});
