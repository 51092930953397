import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';
import ScrollToTop from 'components/scrollToTop/ScrollToTop';
import { setSessionToken } from 'internal/actions/appActions';
import Splash from 'internal/components/Splash';
import useApi, { Methods } from 'internal/hooks/useApi';
import ThemeProvider from 'internal/providers/ThemeProvider';
import { selectSessionToken } from 'internal/selectors/authSelectors';
import { selectCollName } from 'internal/selectors/remoteConfigSelectors';
import { setUser } from 'pages/Account/actions/userActions';
import { USER_DETAIL_ENDPOINT } from 'pages/Account/constants/endpoints';
import { UserType } from 'pages/Account/types/user';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Router from 'router/Router';
import Snackbar from '../../components/Snackbar/Snackbar';
import withConfig from '../../hoc/withConfig';
import useSnackbar from '../../hook/useSnackbar';
import SnackbarSeverity from '../constants/snackbar';
import { t } from '../i18n';

function withQueryClientProvider<T>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithQueryClient = (props: T) => {
    const { setAlert } = useSnackbar();
    const dispatch = useDispatch();

    const queryClient = useMemo(() => {
      return new QueryClient({
        defaultOptions: {
          queries: {
            retry: 0,
            staleTime: 400_000,
            refetchOnWindowFocus: false,
            useErrorBoundary: false,
            suspense: true,
            onError: (e: unknown) => {
              if (axios.isAxiosError(e))
                if (e?.response?.data?.code === 'error.invalidOrExpiredToken') {
                  dispatch(setSessionToken(null));
                  dispatch(setUser(null));
                }
            },
          },
          mutations: {
            onError: (e: unknown) => {
              if (axios.isAxiosError(e)) {
                setAlert({
                  description: t(e?.response?.data?.code || 'error.default'),
                  severity: SnackbarSeverity.ERROR,
                });
                if (e?.response?.data?.code === 'error.invalidOrExpiredToken') {
                  dispatch(setSessionToken(null));
                  dispatch(setUser(null));
                }
              }
            },
          },
        },
      });
    }, [setAlert]);

    return (
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <WrappedComponent {...props} />
      </QueryClientProvider>
    );
  };

  ComponentWithQueryClient.displayName = `withQueryClientProvider(${displayName})`;

  return ComponentWithQueryClient;
}

function App(): ReactElement {
  const dispatch = useDispatch();
  const collectivityName = useSelector(selectCollName);
  const token = useSelector(selectSessionToken);

  const { request: fetchUser, response: responseUser } = useApi<UserType>(Methods.GET, USER_DETAIL_ENDPOINT);

  useEffect(() => {
    if (token) {
      fetchUser();
    }
  }, [token]);

  useEffect(() => {
    if (responseUser?.success && responseUser?.data) {
      dispatch(setUser(responseUser.data));
    }
  }, [responseUser]);

  return (
    <BrowserRouter>
      <Helmet>
        <meta charSet={'utf-8'} />
        <title>{collectivityName}</title>
      </Helmet>
      <ThemeProvider>
        <ScrollToTop />
        <Router />
        <Snackbar />
        <Splash />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default withQueryClientProvider(withConfig(App));
