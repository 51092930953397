import classNames from 'classnames';
import Dropdown from 'components/Dropdown/Dropdown';
import Link from 'components/link/Link';
import useIsAuthenticated from 'hook/auth/useIsAuthenticated';
import React, { HtmlHTMLAttributes, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import routes, { RouteType } from 'router/routes';
import MenuDropdown from './MenuDropdown';

type MenuItem = {
  label: string;
} & (
  | {
      to: RouteType;
    }
  | {
      children: {
        label: string;
        to: RouteType;
      }[];
    }
);

const DesktopNavbar: React.FC<HtmlHTMLAttributes<HTMLDivElement>> = (props) => {
  const { t } = useTranslation();
  const itemClassName = 'uppercase font-bold text-xs text-navbar-color hover:text-navbar-hover';
  const className = classNames(props.className);
  const isAuthenticated = useIsAuthenticated();

  const MENU = useMemo<MenuItem[][]>(
    () => [
      [{ label: t('navbar.contact'), to: routes.contact }],
      [
        {
          label: t('global.book'),
          children: [
            {
              label: t('global.rent_bike'),
              to: routes.rentBike.children.resaBike,
              params: { type: 'velo' },
            },
            {
              label: t('global.rent_storage'),
              to: routes.rentBike.children.resaBike,
              routeName: 'rentPark',
              params: { type: 'consigne' },
            },
            {
              label: t('global.rent_storage_free'),
              to: routes.rentBike.children.resaBike,
              routeName: 'rentStorage',
              params: { type: 'consigne-libre' },
            },
          ],
        },
        {
          label: t('global.repair_and_overhaul'),
          to: routes.repairAndOverhaul,
        },
      ],
    ],
    [t]
  );

  return (
    <div className={className}>
      {MENU[isAuthenticated ? 1 : 0].map((menuItem) => {
        return 'children' in menuItem ? (
          <Dropdown key={menuItem.label} className={'flex'} buttonClassName={itemClassName} title={menuItem.label}>
            <MenuDropdown list={menuItem.children} />
          </Dropdown>
        ) : (
          <Link key={menuItem.label} className={itemClassName} to={menuItem.to}>
            {menuItem.label}
          </Link>
        );
      })}
    </div>
  );
};

export default DesktopNavbar;
