import { selectSessionToken } from 'internal/selectors/authSelectors';
import { useSelector } from 'react-redux';

const useIsAuthenticated = () => {
  const token = useSelector(selectSessionToken);

  return !!token;
};

export default useIsAuthenticated;
