import classNames from 'classnames';
import { selectSessionToken } from 'internal/selectors/authSelectors';
import React from 'react';
import { useSelector } from 'react-redux';

type Props = React.HtmlHTMLAttributes<HTMLDivElement>;

const MainDefault = (props: Props) => {
  const sessionToken = useSelector(selectSessionToken);

  const mainClassName = classNames(
    props.className,
    { 'main--with-big-footer': sessionToken },
    'mt-header-sm md:mt-header-md'
  );

  return <main className={mainClassName}>{props.children}</main>;
};

export default MainDefault;
