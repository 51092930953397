import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useIsAuthorize from './useIsAuthorize';

const useRequireAuthorize = (path: string) => {
  const isAuthorize = useIsAuthorize(path);
  const navigate = useNavigate();

  useEffect(() => {
    !isAuthorize && navigate('/', { replace: true });
  }, [isAuthorize]);
};

export default useRequireAuthorize;
