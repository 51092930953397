import classNames from 'classnames/bind';
import React from 'react';
import styles from './button.module.scss';

const cx = classNames.bind(styles);

enum COLORS {
  primary = 'ButtonPrimary',
  secondary = 'ButtonSecondary',
  outlinedPrimary = 'ButtonOutlinedPrimary',
}

interface ButtonProps<T extends React.ElementType> {
  as?: T;
  children?: React.ReactNode;
  color?: keyof typeof COLORS;
}

const NewButton = <T extends React.ElementType = 'button'>({
  children,
  as,
  color = 'primary',
  className,
  ...props
}: ButtonProps<T> & Omit<React.ComponentPropsWithoutRef<T>, keyof ButtonProps<T>>) => {
  const Component = as || 'button';

  const buttonClassName = cx(
    'Button',
    COLORS[color],
    {
      'px-8': className ? !className.includes('px') : true,
    },
    // 'inline-block rounded-lg py-4 md:py-2 text-center font-bold',
    className
  );

  return (
    <Component className={buttonClassName} {...props}>
      {children}
    </Component>
  );
};

export default NewButton;
