import React, { PropsWithChildren } from 'react';
import { motion } from 'framer-motion';
import Navbar from '../components/navbar/NewNavbar';
import Footer from '../components/footer/NewFooter';

export function AnimatedPageWrapper({ children }: PropsWithChildren<unknown>) {
  return (
    <motion.div
      transition={{ duration: 0.2, ease: 'circIn' }}
      initial={{ opacity: 0, z: 10 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </motion.div>
  );
}

function AppLayout({ children }: PropsWithChildren<unknown>) {
  return (
    <>
      <Navbar />
      <React.Suspense
        fallback={
          <main>
            <span>loading ...</span>
          </main>
        }
      >
        {/*<AnimatePresence>*/}
        {children}
        {/*<Outlet />*/}
        {/*</AnimatePresence>*/}
      </React.Suspense>
      <Footer />
    </>
  );
}

export default AppLayout;
