import {
  SetAccessoriesUsedActionType,
  SetPdlSelectedActionType,
  SetProductDetailsActionType,
  SetRentalPricesActionType,
  SetRentalStartDateActionType,
} from 'pages/Resabike/types/actions';
import { PriceTableType } from 'pages/Resabike/types/prices';
import { BikeProductType } from 'pages/Resabike/types/product';
import { ResaAccessoryItemType } from 'pages/Resabike/types/resabike';

export const SET_RENTAL_PRICES = 'SET_RENTAL_PRICES';
export const SET_RENTAL_START_DATE = 'SET_RENTAL_START_DATE';
export const SET_PRODUCT_DETAILS = 'SET_PRODUCT_DETAILS';
export const SET_ACCESSORIES_USED = 'SET_ACCESSORIES_USED';
export const SET_PDL_SELECTED = 'SET_PDL_SELECTED';

export const setRentalPrices = (priceTable: PriceTableType): SetRentalPricesActionType => ({
  type: SET_RENTAL_PRICES,
  payload: {
    priceTable,
  },
});

export const setRentalStartDate = (rentalStartDate: string): SetRentalStartDateActionType => ({
  type: SET_RENTAL_START_DATE,
  payload: {
    rentalStartDate,
  },
});

export const setProductDetails = (productDetails: BikeProductType): SetProductDetailsActionType => ({
  type: SET_PRODUCT_DETAILS,
  payload: {
    productDetails,
  },
});

export const setAccessoriesUsed = (accessoriesUsed: ResaAccessoryItemType[] | null): SetAccessoriesUsedActionType => ({
  type: SET_ACCESSORIES_USED,
  payload: {
    accessoriesUsed,
  },
});

export const setPdl = (pdlSelected: number | null): SetPdlSelectedActionType => ({
  type: SET_PDL_SELECTED,
  payload: {
    pdlSelected,
  },
});
