import { AxiosInstance, AxiosRequestConfig } from 'axios';

let mockingEnabled = false;

//eslint-disable-next-line
const mocks: Record<string, any> = {};

class MockError extends Error {
  //eslint-disable-next-line
  public mockData: any;
  //eslint-disable-next-line
  public config: any;
  //eslint-disable-next-line
  public code: any;

  constructor(message?: string) {
    super(message);
  }
}

//eslint-disable-next-line
export function addMock(url: string, data: any) {
  Object.assign(mocks, {
    [url]: data,
  });
}

export function enableMocking(state: boolean) {
  mockingEnabled = state;
}

const isUrlMocked = (url?: string) => (url ? url in mocks : false);

const getMockError = (url: string, config: AxiosRequestConfig) => {
  const mockError = new MockError();
  mockError.mockData = mocks[url];
  mockError.config = config;
  return Promise.reject(mockError);
};

const isMockError = (error: MockError) => 'mockData' in error;

const getMockResponse = (mockError: MockError) => {
  const { mockData, config } = mockError;
  // Handle mocked error (any non-2xx status code)
  if (mockData.status && String(mockData.status)[0] !== '2') {
    const err = new MockError(mockData.message || 'mock error');
    err.code = mockData.status;
    return Promise.reject(err);
  }
  // Handle mocked success
  return Promise.resolve(
    Object.assign(
      {
        data: {},
        status: 200,
        statusText: 'OK',
        headers: {},
        config,
        isMock: true,
      },
      mockData
    )
  );
};

export function addMockingInterceptors(instance: AxiosInstance) {
  // Add a request interceptor
  instance.interceptors.request.use(
    (config) => {
      try {
        const url = config.url?.concat('?').concat(
          Object.entries(config?.params || {})
            .map(([key, value]) => `${key}=${value}`)
            .join('&')
        );
        if (url && mockingEnabled && isUrlMocked(url)) {
          console.info('axios mocking ' + url);
          return getMockError(url, config);
        }
      } catch (e) {
        console.error(e);
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (isMockError(error)) {
        return getMockResponse(error);
      }
      return Promise.reject(error);
    }
  );
}
