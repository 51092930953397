import { useEffect } from 'react';

type AnyEvent = MouseEvent | TouchEvent;

function useOnClickOutside(el: Element | null, handler: (event: AnyEvent) => void): void {
  useEffect(() => {
    const listener = (event: AnyEvent) => {
      if (!el || el.contains(event.target as Node)) {
        return;
      }

      handler(event);
    };

    document.addEventListener(`mousedown`, listener);
    document.addEventListener(`touchstart`, listener);

    return () => {
      document.removeEventListener(`mousedown`, listener);
      document.removeEventListener(`touchstart`, listener);
    };
  }, [el, handler]);
}

export default useOnClickOutside;
