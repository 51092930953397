import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

type LoadingContext = {
  loading: boolean;
  dataIsLoaded: boolean;
  setLoading: (value: boolean) => void;
  setDataIsLoaded: (value: boolean) => void;
};

const LoadingContext = createContext<LoadingContext | null>(null);

function LoadingProvider({ children }: PropsWithChildren<unknown>) {
  const [loading, setLoading] = useState(true);
  const [dataIsLoaded, setDataIsLoaded] = useState(false);

  return (
    <LoadingContext.Provider value={{ loading, setLoading, dataIsLoaded, setDataIsLoaded }}>
      {children}
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoadingContext);
  if (!context) throw new Error('useLoading must be used within LoadingProvider');

  return context;
}

export default LoadingProvider;
