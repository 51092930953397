import { SetUserActionType } from '../types/actions';
import { UserType } from '../types/user';

export const SET_USER = 'SET_USER';

export const setUser = (user: UserType | null): SetUserActionType => ({
  type: SET_USER,
  payload: {
    user,
  },
});
