import axios from 'axios';
import { setSessionToken } from 'internal/actions/appActions';
import SnackbarSeverity from 'internal/constants/snackbar';
import Status from 'internal/constants/status';
import { t } from 'internal/i18n';

import api, { RequestType } from 'internal/infrastructure/api';
import { selectSessionToken } from 'internal/selectors/authSelectors';
// import { useNavigate } from 'react-router';
// import { SIGN_IN } from 'internal/constants/routes';
import { setUser } from 'pages/Account/actions/userActions';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSnackbar from '../../hook/useSnackbar';

export enum Methods {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'del',
}

type Response<DataType> = {
  success: boolean;
  data: DataType;
};

type Query = {
  method: Methods;
  endpoint: string;
};

export type UseApiOutput<DataType> = {
  request: (options?: RequestType) => void;
  response: Response<DataType> | null;
  status: Status;
};

type UseApiOptions = {
  noSnack?: boolean;
  onError?: (error: string) => void;
};

const useApi = <DataType>(
  method: Methods,
  endpoint: string,
  config?: UseApiOptions,
  successCallback?: (data: DataType) => void
): UseApiOutput<DataType> => {
  const { setAlert } = useSnackbar();
  const dispatch = useDispatch();

  const token = useSelector(selectSessionToken);

  const query = useRef<Query>({ method, endpoint });

  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [response, setResponse] = useState<Response<DataType> | null>(null);

  useEffect(() => {
    if (!token) {
      api.removeAuthToken();
    } else {
      api.setAuthToken(token);
    }
  }, [token]);

  useEffect(() => {
    query.current = { method, endpoint };
  }, [method, endpoint]);

  const request = useCallback(
    async (options: RequestType = {}) => {
      let cancelled = false;
      try {
        setStatus(Status.LOADING);
        const { method, endpoint } = query.current;

        const data = await api[method](endpoint, options);

        setStatus(Status.SUCCESS);
        setResponse({ success: true, data });
        successCallback && successCallback(data);
        return;
      } catch (e) {
        console.error('error', axios.isAxiosError(e));
        if (!cancelled && axios.isAxiosError(e)) {
          setStatus(Status.FAILURE);
          setResponse({ success: false, data: e?.response?.data?.code });

          if (config?.onError) {
            config?.onError(e.response?.data?.code);
          }

          if (!config?.noSnack) {
            setAlert({ description: t(e?.response?.data?.code || 'error.default'), severity: SnackbarSeverity.ERROR });
          }

          if (e?.response?.data?.code === 'error.invalidOrExpiredToken') {
            dispatch(setSessionToken(null));
            dispatch(setUser(null));
          }
        }
      }

      return () => {
        cancelled = true;
      };
    },
    [query, successCallback]
  );

  return {
    request,
    response,
    status,
  };
};

export default useApi;
