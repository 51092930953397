import { SET_USER } from 'pages/Account/actions/userActions';
import { SetUserActionType } from '../types/actions';
import { UserType } from '../types/user';

type UserState = {
  user: UserType | null;
};

const initialState = {
  user: null,
};

const userReducer = (state: UserState = initialState, action: SetUserActionType): UserState => {
  switch (action.type) {
    case SET_USER:
      const { user } = action.payload;

      return {
        ...state,
        user,
      };

    default:
      return state;
  }
};

export default userReducer;
