import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectRemoteTheme } from 'internal/selectors/remoteConfigSelectors';
import { appendStyle, buildThemeFromRemote, isDOMReady } from 'internal/utils/themeUtils';

type Context = {
  cssReady: boolean;
};

export const defaultContext: Context = {
  cssReady: false,
};

const StateContext = createContext<Context>(defaultContext);

interface Props {
  children: ReactNode;
  onCssReady?: () => void;
}

const ThemeProvider = ({ children }: Props): React.ReactElement => {
  // const [cssReady, setCssReady] = useState<boolean>(false);
  const remoteTheme = useSelector(selectRemoteTheme);
  const domReady = isDOMReady();

  useEffect(() => {
    if (remoteTheme && domReady) {
      const newRemoteTheme = buildThemeFromRemote(remoteTheme);
      appendStyle('theme', newRemoteTheme);
      // appendClassNames(remoteTheme);
    }
    // const timeout = setTimeout(() => {
    // setCssReady(true);
    // }, 1200);

    // return () => clearTimeout(timeout);
  }, [remoteTheme, domReady]);

  /*useEffect(() => {
    if (cssReady) {
      onCssReady();
    }
  }, [cssReady]);*/

  return (
    /*<StateContext.Provider
      value={{
        cssReady,
      }}>*/
    // </StateContext.Provider>
    <div id={'theme'}>{children}</div>
  );
};

export const useTheme = (): Context => useContext(StateContext);

export default ThemeProvider;
