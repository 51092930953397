import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ROUTES from 'router/routes';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { selectRootConfig } from '../internal/selectors/remoteConfigSelectors';

function useGetFooterLinks() {
  const { t } = useTranslation();
  const rootConfig = useSelector(selectRootConfig);

  const legalLinks = useMemo(
    () => _(rootConfig?.client).pick(['legal_link', 'privacy_link', 'cgvelo_link']).omitBy(_.isNull).value(),
    [rootConfig]
  );

  return useMemo(() => {
    const base = [
      {
        label: t('global.service'),
        links: [
          {
            label: t('global.rent_bike'),
            to: ROUTES.rentBike,
            params: { type: 'velo' },
          },
          {
            label: t('global.workshop'),
            to: ROUTES.workshop,
          },
          {
            label: t('global.repair_and_overhaul'),
            to: ROUTES.repairAndOverhaul,
          },
          {
            label: t('contact.contact_us'),
            to: ROUTES.contact,
          },
        ],
      },
      {
        label: t('global.my_account'),
        links: [
          {
            label: t('global.my_reservations'),
            to: ROUTES.myReservations,
          },
          {
            label: t('global.my_invoices'),
            to: ROUTES.myInvoices,
          },
        ],
      },
    ];

    if (_.keys(legalLinks).length !== 0)
      base.push({
        label: t('global.legal_section'),
        links: _(legalLinks)
          .map((value, key) => ({ label: t('global.'.concat(key)), target: '_blank', to: { path: value } }))
          //eslint-disable-next-line
          .value() as any,
      });

    return base;
  }, [t, legalLinks]);
}

export default useGetFooterLinks;
