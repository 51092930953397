import SnackbarSeverity from 'internal/constants/snackbar';
import { WordingsType } from 'internal/mocks/wordings';
import {
  ChangeLocaleActionType,
  HideSnackbarActionType,
  SaveDefaultLocaleActionType,
  SaveDynamicWordingsActionType,
  SavePageWordingActionType,
  SaveRemoteConfigActionType,
  SetSessionTokenActionType,
  SetSnackbarActionType,
  ShowSnackbarActionType,
} from 'internal/types/actions';
import { RemoteConfigType } from 'internal/types/remoteConfig';

export const SET_SESSION_TOKEN = 'SET_SESSION_TOKEN';
export const REMOVE_SESSION_TOKEN = 'REMOVE_SESSION_TOKEN';
export const SET_SNACKBAR_CONTENT = 'SET_SNACKBAR_CONTENT';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const SAVE_DEFAULT_LOCALE = 'SAVE_DEFAULT_LOCALE';
export const SAVE_REMOTE_CONFIG = 'SAVE_REMOTE_CONFIG';
export const SAVE_PAGE_WORDINGS = 'SAVE_PAGE_WORDINGS';
export const SAVE_DYNAMIC_WORDINGS = 'SAVE_DYNAMIC_WORDINGS';

export const setSessionToken = (token: string | null): SetSessionTokenActionType => ({
  type: SET_SESSION_TOKEN,
  payload: {
    token,
  },
});

export const setSnackbar = (title: string, content: string, severity?: SnackbarSeverity): SetSnackbarActionType => ({
  type: SET_SNACKBAR_CONTENT,
  payload: {
    title,
    content,
    severity,
  },
});

export const showSnackbar = (): ShowSnackbarActionType => ({
  type: SHOW_SNACKBAR,
});

export const hideSnackbar = (): HideSnackbarActionType => ({
  type: HIDE_SNACKBAR,
});

export const changeLocale = (locale: string): ChangeLocaleActionType => ({
  type: CHANGE_LOCALE,
  payload: {
    locale,
  },
});

export const saveDefaultLocale = (locale: string): SaveDefaultLocaleActionType => ({
  type: SAVE_DEFAULT_LOCALE,
  payload: {
    locale,
  },
});

export const saveRemoteConfig = (config: RemoteConfigType): SaveRemoteConfigActionType => ({
  type: SAVE_REMOTE_CONFIG,
  payload: {
    config,
  },
});

export const savePageWordings = (wordings: WordingsType): SavePageWordingActionType => ({
  type: SAVE_PAGE_WORDINGS,
  payload: {
    wordings,
  },
});

export const saveDynamicWordings = (dynamicWordings: Record<string, string>): SaveDynamicWordingsActionType => ({
  type: SAVE_DYNAMIC_WORDINGS,
  payload: {
    dynamicWordings,
  },
});
