import React, { ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { t } from 'internal/i18n';
import MainDefault from 'components/mainDefault/MainDefault';
import NewButton from 'components/button/NewButton';
import { NotFoundIllustration } from 'const/icons';

const PageNotFound = (): ReactElement => {
  const navigate = useNavigate();

  const handleGoHome = useCallback(() => {
    // TODO: or -1 if router state ?
    navigate('/', { replace: true });
  }, []);

  return (
    <MainDefault className={'flex'}>
      <section className={'container flex flex-col justify-center items-center'}>
        <NotFoundIllustration className={'text-primary-200'} width={362} />
        <span className={'font-bold mt-8 text-sm'}>{t('global.page_not_found.title')}</span>
        <p className={'mt-1 w-72 text-center text-xs'}>{t('global.page_not_found.wording')}</p>
        <NewButton className={'mt-4 w-full py-4 md:py-2 md:w-[260px]'} onClick={handleGoHome}>
          {t('global.go_home')}
        </NewButton>
      </section>
    </MainDefault>
  );
};

export default PageNotFound;
