import classNames from 'classnames';
import useIsAuthorize from 'hook/auth/useIsAuthorize';
import { selectRemoteTheme } from 'internal/selectors/remoteConfigSelectors';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, NavLink } from 'react-router-dom';
import { RouteType } from 'router/routes';
import useIsAuthenticated from '../../hook/auth/useIsAuthenticated';

type LinkProps<T = Record<string, unknown>> = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  children?: React.ReactNode;
  routeName?: string;
  to: RouteType<T>; // TODO: abstract this with a own type in Link and create one function like getRoute (abstract Router class)
  params?: T;
  queryParams?: string;
  innerRef?: React.Ref<HTMLAnchorElement>;
  external?: boolean;
  notThemed?: boolean;
  inContent?: boolean;
};

export function build<T>(route: RouteType<T>, params: T, queryParams: string) {
  return generatePath(route.path + queryParams, params && Object.fromEntries(Object.entries(params)));
}

const Link: React.FunctionComponent<LinkProps> = ({
  children,
  queryParams = '',
  params,
  to,
  className,
  notThemed = false,
  routeName,
  inContent = false,
  ...props
}) => {
  const isAuthorize = useIsAuthorize(to.path, routeName);
  const isAuthenticated = useIsAuthenticated();
  const theme = useSelector(selectRemoteTheme);
  const linkClassName = classNames(inContent && 'text-primary-200 hover:underline decoration-primary-200', className);
  const [style, setStyle] = useState({});
  const path = params || queryParams ? build(to, params, queryParams) : to.path;

  useEffect(() => {
    document.documentElement.style.setProperty('--a-color', theme?.a_color || '#ccc');

    // if (!notThemed) setStyle({ color: 'var(--a-color)' });
  }, [theme, notThemed, setStyle]);

  return isAuthorize && (to.privateRoute ? isAuthenticated : true) ? (
    path[0] === '/' ? (
      <NavLink style={style} to={path} className={linkClassName} {...props}>
        {children}
      </NavLink>
    ) : (
      <a style={style} className={linkClassName} href={path} {...props}>
        {children}
      </a>
    )
  ) : null;
};

export default Link;
