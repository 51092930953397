import { ReservationDetailsType } from 'pages/Resabike/types/resabike';
import { SetRenewalBikeActionType } from '../types/actions';

export const SET_RENEWAL_BIKE = 'SET_RENEWAL_BIKE';

export const setRenewalBike = (reservationDetails: ReservationDetailsType | null): SetRenewalBikeActionType => ({
  type: SET_RENEWAL_BIKE,
  payload: {
    reservationDetails,
  },
});
