import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Link from 'components/link/Link';
import routes from 'router/routes';
import Dropdown from 'components/Dropdown/Dropdown';
import useIsAuthenticated from 'hook/auth/useIsAuthenticated';
import { useSelector } from 'react-redux';
import { selectRemoteTheme } from 'internal/selectors/remoteConfigSelectors';
import LangSelect from 'components/LangSelect/LangSelect';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { CloseIcon, MenuIcon } from '../../const/icons';
import styles from './navbar.module.css';
import MenuDropdown from './MenuDropdown';
import DesktopNavbar from './DesktopNavbar';

type FullModalProps = PropsWithChildren<{
  isOpen: boolean;
  handleClose: () => void;
}>;

const FullModal: React.FC<FullModalProps> = ({ children, isOpen, handleClose }) => {
  const bodyRef = useRef<Element | null>(null);

  useEffect(() => {
    bodyRef.current = window.document.body;
  }, []);

  useEffect(() => {
    isOpen
      ? window.document.body.setAttribute('style', 'overflow: hidden')
      : window.document.body.setAttribute('style', '');
  }, [isOpen]);

  if (bodyRef.current !== null)
    return createPortal(
      <div className={classNames('bg-primary-400 fixed z-50 inset-0 w-full h-screen p-4', !isOpen && 'hidden')}>
        <button onClick={handleClose}>
          <CloseIcon width={40} />
        </button>
        {children}
      </div>,
      bodyRef.current
    );

  return null;
};

const MobileMenu: React.FC = () => {
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

  const toggleMobileMenu = useCallback(() => setMobileMenuIsOpen(!mobileMenuIsOpen), [mobileMenuIsOpen]);

  const MOBILE_NAV_LINKS = useMemo(() => {
    const list = [
      {
        label: t('global.home'),
        link: { to: routes.home },
      },
      {
        label: t('global.rent_bike'),
        link: { to: routes.rentBike, params: { type: 'velo' } },
      },
      {
        label: t('global.repair_and_overhaul'),
        link: { to: routes.repairAndOverhaul },
      },
      {
        label: t('global.my_account'),
        link: { to: routes.myAccount },
      },
    ];

    if (isAuthenticated) {
      list.push({
        label: t('global.sign_out'),
        link: { to: routes.signOut },
      });
    } else {
      list.push({
        label: t('navbar.contact'),
        link: { to: routes.contact },
      });
    }

    return list;
  }, [isAuthenticated, t]);

  return (
    <>
      <button onClick={toggleMobileMenu}>
        <MenuIcon className={'text-navbar-color'} width={32} />
      </button>
      <FullModal handleClose={toggleMobileMenu} isOpen={mobileMenuIsOpen}>
        <div className={'grid gap-8 mt-8'}>
          {MOBILE_NAV_LINKS.map((item) => {
            return (
              <Link key={item.label} onClick={toggleMobileMenu} className={styles.Link} {...item.link}>
                {item.label}
              </Link>
            );
          })}
        </div>
      </FullModal>
    </>
  );
};

const mediaQuery = 'screen and (min-width: 768px)';

function NewNavbar() {
  const { t } = useTranslation();
  const isAuthenticated = useIsAuthenticated();
  const theme = useSelector(selectRemoteTheme);
  const mql = window.matchMedia(mediaQuery);
  const [showDesktopMenu, setShowDesktopMenu] = useState(mql.matches);

  const homeRoute = useMemo(() => {
    if (isAuthenticated) return routes.home;

    return routes.signIn;
  }, [isAuthenticated]);

  useEffect(() => {
    const handleMediaChange = function (this: MediaQueryList) {
      setShowDesktopMenu(this.matches);
    };
    mql.addEventListener('change', handleMediaChange);
    setShowDesktopMenu(mql.matches);

    return () => {
      mql.removeEventListener('change', handleMediaChange);
    };
  }, [mql]);

  return (
    <header
      className={
        'bg-navbar-background fixed md:absolute top-0 inset-x-0 h-header-sm md:h-header-md w-full z-50 flex items-center'
      }
    >
      <div className={'container h-full mx-auto flex justify-between items-center'}>
        {!showDesktopMenu && <MobileMenu />}
        <Link className={'h-full flex justify-center py-1'} to={homeRoute}>
          <img className={'w-auto h-full'} src={theme?.navbarLogo} alt={'collectivity-logo'} />
        </Link>
        {showDesktopMenu && <DesktopNavbar className={'md:flex items-center flex-1 ml-16 gap-7'} />}
        <div className={'flex items-center'}>
          {isAuthenticated && (
            <Dropdown
              title={t('global.my_account')}
              className={'hidden md:block'}
              buttonClassName={'uppercase text-navbar-color hover:text-navbar-hover'}
            >
              {() => (
                <MenuDropdown
                  list={[
                    { label: t('global.my_infos'), to: routes.myAccount },
                    { label: t('global.sign_out'), to: routes.signOut },
                  ]}
                />
              )}
            </Dropdown>
          )}
          <LangSelect />
        </div>
      </div>
    </header>
  );
}

export default NewNavbar;
